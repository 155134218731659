import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Accordion, Container, Form } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection'
import Heading from '../components/Heading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Career = () => {
  const [career, setCareer] = useState()
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    collegeName: '',
    qualification: '',
    position: '',
    enquiry_type: 'career'
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if(formData.name === '' || formData.email === '' || formData.mobile === '' || formData.collegeName === '' || formData.qualification === '' || formData.position === ''){
      const notify = () => toast.error("Please provide all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: 'colored'
      })
      notify()
    }
    else {
      try {
        await axios.post(`${window.apiURL}/enquiry`, {
          name: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          collegeName: formData.collegeName,
          qualification: formData.qualification,
          position: formData.position,
          enquiry_type: formData.enquiry_type
        })
      const notify = () => toast.success("Successfully Submitted", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: 'colored'
      })
      notify()
      }
      catch (error) {
        alert(error)
      }
    }
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/career`)
      setCareer(res.data.careers)
    }
    getData();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [career])

  useEffect(() => {
    document.title = "CollegeVidhyarthi | Career"
  }, []);

  return (
    <div>
      <BreadcrumbSection activePage="career" pageTitle="career" />
      <section className='career-section'>
        <Container fluid>
          <Heading heading="we are hiring" />
          <div className='text-center'>
            <div>At Proftcode, we are a team of young and enthusiastic generation dedicated to make an impact on college selection procedure in India. We are engrossed in making our
              website a nationwide hit and the most-sought-for reference for students across country.

              Now that’s tough, think so? Well, that’s not all of it. We do have the fun-fever
              tagged along as we step into our office.
              If you are willing to join hands with us, you should expect fun spilling out of your pockets.
              Yes, we do require your fervor towards your work but never mind, we have several cool stuffs in store to make your Proftcode experience</div>
          </div>
          <div className="mt-40">
            <Heading heading="current openings" />
            <Accordion>
              {
                career && career.map((item) => {
                  return (
                    <Accordion.Item key={item.career_id}>
                      <Accordion.Header>
                        <h4 className="jobTitle">
                          {item.title}
                        </h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div className="description">
                            <span dangerouslySetInnerHTML={{ __html: item.description }} />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })
              }
            </Accordion>
          </div>
        </Container>
        <div className="carrer_form">
          <Container fluid>
            <div className="career_form_content">
              <h4>Leave your Details here</h4>
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required type="text" name="name" placeholder='Full Name' onChange={handleChange} value={formData.name} />
                  <Form.Control.Feedback type="invalid">
                    Please provide Full Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required type="text" name="email" placeholder='Email' onChange={handleChange} value={formData.email} />
                  <Form.Control.Feedback type="invalid">
                    Email is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required name="mobile" placeholder='Mobile No.' onChange={handleChange} value={formData.mobile} />
                  <Form.Control.Feedback type="invalid">
                    Please provide Mobile No.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required type="text" name="collegeName" placeholder='Enter Your College Name' onChange={handleChange} value={formData.collegeName} />
                  <Form.Control.Feedback type="invalid">
                    Please provide College Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <select name="qualification" onChange={handleChange} value={formData.qualification} required >
                  <option value="Selected">Select Your Highest Qualification</option>
                  <optgroup label="Popular Courses">
                    <option value="B.tech">B.tech</option>
                    <option value="PGDM">PGDM</option>
                    <option value="B.com">B.com</option>
                    <option value="BA">BA</option>
                    <option value="MA">MA</option>
                    <option value="MBBS">MBBS</option>
                    <option value="M.tech">M.tech</option>
                    <option value="BCA">BCA</option>
                    <option value="MCA">MCA</option>
                  </optgroup>
                  <optgroup label="Bachelors">
                    <option value="B.tech">B.tech</option>
                    <option value="B.com">B.com</option>
                    <option value="BA">BA</option>
                    <option value="BCA">BCA</option>
                  </optgroup>
                  <optgroup label="Masters">
                    <option value="MA">MA</option>
                    <option value="MBBS">MBBS</option>
                    <option value="M.tech">M.tech</option>
                    <option value="MCA">MCA</option>
                  </optgroup>
                </select>
                <select name="position" onChange={handleChange} value={formData.position} required>
                  <option value="Selected">Select a Position to Apply</option>
                  {career && career.map((option) => {
                    return (
                      <option value={option.title} key={option.career_id}>{option.title}</option>
                    )
                  })}
                </select>
                <input type="hidden" name="enquiry_type" value={formData.enquiry_type} />
                <input type="submit" value="Submit" />
              </Form>
            </div>
          </Container>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}

export default Career
