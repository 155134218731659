import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection'
import { FaTimes } from 'react-icons/fa'
import Slider from 'react-slick/lib/slider'

const Gallery = () => {
  const [gallery, setGallery] = useState('');

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/gallery`);
      setGallery(res.data.gallerys);
    }
    getData();
  }, []);
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "CollegeVidhyarthi | Gallery"
  }, [])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      {/* <BreadcrumbSection img={galleryImg} activePage="Gallery" pageTitle="Gallery" /> */}
      <BreadcrumbSection img='' activePage="Gallery" pageTitle="Gallery" />
      <section className="gallery-section">
        <Container fluid>
          <Row className='gallery-images'>
            {gallery && gallery.map((data) =>
              <Col xl={3} md={4} sm={6} key={data.gallary_id}>
                <Button variant="primary" onClick={handleShow}>
                  <img src={window.imgURL + "//gallary/" + data.image} alt="" />
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} className='gallery-modal' centered>
        <Modal.Body>
          <Button className='close-btn' onClick={handleClose}>
            <FaTimes />
          </Button>
            <Slider {...settings}>
            {
              gallery && gallery.map((item) => {
                return (
                  <div key={item.gallary_id}>
                    <img src={window.imgURL + "//gallary/" + item.image} alt="" />
                  </div>
                )
              })
            }
          </Slider>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Gallery
