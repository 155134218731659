import collegeImg1 from './images/colleges/two.jpg'
import collegeImg2 from './images/colleges/three.jpg'
import collegeImg3 from './images/colleges/one.jpg'
import collegeLogo1 from './images/colleges/logo1.jpg'
import collegeLogo2 from './images/colleges/logo2.jpg'
import collegeLogo3 from './images/colleges/logo3.jpeg'

import overlay1 from './images/counselling/one.jpg'

import goalsImg1 from "./images/goals/engineering.png";
import goalsImg2 from "./images/goals/management.png";
import goalsImg3 from "./images/goals/medical.png";
import goalsImg4 from "./images/goals/science.png";
import goalsImg5 from "./images/goals/commerce.png";

import newsImg2 from './images/news/news (2).jpg'
import newsImg1 from './images/news/news (1).jpg'
import newsImg3 from './images/news/news (3).jpg'
import newsImg4 from './images/news/news (4).jpg'

import flag1 from './images/studyAbroad/usa.png'
import flag2 from './images/studyAbroad/uk.png'
import flag3 from './images/studyAbroad/canada.png'
import flag4 from './images/studyAbroad/australia.png'
import flag5 from './images/studyAbroad/germany.png'
import flag6 from './images/studyAbroad/ireland.png'
import flag7 from './images/studyAbroad/new-zealand.png'

import testimonialImg1 from './images/testimonials/person2.jpg'
import testimonialImg2 from './images/testimonials/person1.jpg'

import collegeDetailsImg1 from './images/colleges/one.jpg'
import collegeDetailslogo1 from "./images/colleges/logo4.png"

import collegeListlogo1 from "./images/colleges/logo4.png"
import collegeListlogo2 from "./images/colleges/logo-1.png"
import collegeListlogo3 from "./images/colleges/logo-2.png"
import collegeListlogo4 from "./images/colleges/logo-3.png"
import collegeListlogo5 from "./images/colleges/logo-4.png"
import collegeListlogo6 from "./images/colleges/logo-5.png"
import collegeListlogo7 from "./images/colleges/logo-6.png"

import gallery1 from "./images/gallery/one.jpg"
import gallery2 from "./images/gallery/two.jpg"
import gallery3 from "./images/gallery/three.jpg"
import gallery4 from "./images/gallery/four.jpg"
import gallery5 from "./images/gallery/five.jpg"
import gallery6 from "./images/gallery/six.jpg"

const courses = [
  {
    id: 1,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Com General",
    duration: "3 Years",
    fees: "56.68 K",
    colleges: "5783",
  },
  {
    id: 2,
    type: "Masters",
    singleBadge: "full time",
    title: "M.Tech Computer Science and Engineering",
    duration: "2 Years",
    fees: "5.41 L",
    colleges: "1292",
  },
  {
    id: 3,
    type: "Doctorate",
    singleBadge: "full time",
    title: "Ph.D Civil Engineering",
    duration: "3 Years",
    fees: "2.10 L",
    colleges: "1190",
  },
  {
    id: 4,
    type: "Diploma",
    singleBadge: "full time",
    title: "Polytechic Electrical Engineering",
    duration: "4 Years",
    fees: "91.41 L",
    colleges: "2998",
  },
  {
    id: 5,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Com General",
    duration: "3 Years",
    fees: "56.68 K",
    colleges: "5783",
  },
  {
    id: 6,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Tech Computer Science and Engineering",
    duration: "4 Years",
    fees: "3.41 L",
    colleges: "3392",
  },
  {
    id: 7,
    type: "Certification",
    singleBadge: "full time",
    title: "Aircraft Maintenance Engineering (AME)",
    duration: "2 Years",
    fees: "3 L",
    colleges: "102",
  },
  {
    id: 8,
    type: "Masters",
    singleBadge: "full time",
    title: "M.Tech Structural Engineering",
    duration: "2 Years",
    fees: "1.75 L",
    colleges: "989",
  },
  {
    id: 9,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Tech Computer Science and Engineering",
    duration: "4 Years",
    fees: "3.41 L",
    colleges: "3392",
  },
  {
    id: 10,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Tech Computer Science and Engineering",
    duration: "4 Years",
    fees: "3.41 L",
    colleges: "3392",
  },
  {
    id: 11,
    type: "Bachelor",
    singleBadge: "full time",
    title: "B.Tech Computer Science and Engineering",
    duration: "4 Years",
    fees: "3.41 L",
    colleges: "3392",
  },
]
const colleges = [
  {
    id: 1,
    img: collegeImg1,
    logo: collegeLogo1,
    alt: "College in Maharashtra",
    name: "Amity University Mumbai (AUM) Guwahati - Indian Institute of Technology - (IITG)",
    location: "Guwahati, Assam | AICTE, UGC"
  },
  {
    id: 2,
    img: collegeImg2,
    logo: collegeLogo2,
    alt: "College in Pune",
    name: "All India Institute of Medical Science - [AIIMS]",
    location: "New Delhi, Delhi NCR | MCi, UGC"
  },
  {
    id: 3,
    img: collegeImg3,
    logo: collegeLogo3,
    alt: "College in Rajasthan",
    name: "IIT Guwahati - Indian Institute of Technology - (IITG)",
    location: "Guwahati, Assam | AICTE, UGC"
  },
  {
    id: 4,
    img: collegeImg1,
    logo: collegeLogo1,
    alt: "College in Maharashtra",
    name: "IIT Guwahati - Indian Institute of Technology - (IITG)",
    location: "Guwahati, Assam | AICTE, UGC"
  },
  {
    id: 5,
    img: collegeImg2,
    logo: collegeLogo2,
    alt: "College in Pune",
    name: "All India Institute of Medical Science - [AIIMS]",
    location: "New Delhi, Delhi NCR | MCi, UGC"
  },
  {
    id: 6,
    img: collegeImg3,
    logo: collegeLogo3,
    alt: "College in Rajasthan",
    name: "IIT Guwahati - Indian Institute of Technology - (IITG)",
    location: "Guwahati, Assam | AICTE, UGC"
  },
]
const counselling = [
  {
    id: 1,
    url: 'https://www.youtube.com/embed/CVpUuw9XSjY',
    overlayImg: overlay1,
    alt: "Free End-to-End Counselling one"
  },
]
const goals = [
  {
    id: 1,
    img: goalsImg1,
    title: "Engineering",
    colleges: "7298",
  },
  {
    id: 2,
    img: goalsImg2,
    title: "Management",
    colleges: "3579",
  },
  {
    id: 3,
    img: goalsImg3,
    title: "Medical",
    colleges: "4500",
  },
  {
    id: 4,
    img: goalsImg4,
    title: "Science",
    colleges: "9081",
  },
  {
    id: 5,
    img: goalsImg5,
    title: "Commerce",
    colleges: "1409",
  },
  {
    id: 6,
    img: goalsImg1,
    title: "Engineering",
    colleges: "7298",
  },
  {
    id: 7,
    img: goalsImg2,
    title: "Management",
    colleges: "3579",
  },
  {
    id: 8,
    img: goalsImg3,
    title: "Medical",
    colleges: "4500",
  },
  {
    id: 9,
    img: goalsImg4,
    title: "Science",
    colleges: "9081",
  },
]
const jobs = [
  {
    id: '1',
    jobTitle: "Graphic Designer",
    experience: "0 to 4 years",
    positions: "2",
    location: 'Gurugram',
    jobDescription: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "5. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "6. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ],
    jobRequirements: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ]
  },
  {
    id: '2',
    jobTitle: "Product Manager",
    experience: "1 to 3 years",
    positions: "4",
    location: 'Jaipur',
    jobDescription: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "5. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "6. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ],
    jobRequirements: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ]
  },
  {
    id: '3',
    jobTitle: "Business Development - Associate / Assistant Manager / Manager",
    experience: "4 to 6 years",
    positions: "5",
    location: 'Jaipur',
    jobDescription: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "5. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "6. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ],
    jobRequirements: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ]
  },
  {
    id: '4',
    jobTitle: "Founder’s Staff",
    experience: "1 to 2 years",
    positions: "5",
    location: 'Surat',
    jobDescription: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "5. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "6. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ],
    jobRequirements: [
      "1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "2. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "3. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
      "4. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, omnis id libero qui velit a. Tenetur quam ipsum delectus laudantium alias! Vero necessitatibus impedit nisi deserunt, ipsa numquam totam fugit.",
    ]
  },
]
const news = [
  {
    id: 1,
    img: newsImg1,
    alt: "Free End-to-End Counselling one",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
  {
    id: 2,
    img: newsImg2,
    alt: "Free End-to-End Counselling two",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
  {
    id: 3,
    img: newsImg3,
    alt: "Free End-to-End Counselling three",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
  {
    id: 4,
    img: newsImg4,
    alt: "Free End-to-End Counselling three",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
  {
    id: 5,
    img: newsImg3,
    alt: "Free End-to-End Counselling three",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
  {
    id: 6,
    img: newsImg4,
    alt: "Free End-to-End Counselling three",
    title: "NID DAT 2022 Prelims Question Paper Analysis (Out), Answer Key and Solutions",
    publishDate: "January 02, 2022 |  By Samiksha Rautela",
    description: "IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are IELTS Reading is the second section of the exam. The test will include a reading passage based on which answers are",
  },
]
const studyAbroad = [
  {
    id: 1,
    img: flag1,
    alt: "College in USA",
    location: "USA",
    cost: "29.46 K USD / Year",
    colleges: 1274
  },
  {
    id: 2,
    img: flag2,
    alt: "College in UK",
    location: "UK",
    cost: "22.14 K USD / Year",
    colleges: 804
  },
  {
    id: 3,
    img: flag3,
    alt: "College in Canada",
    location: "Canada",
    cost: "14.97 K USD / Year",
    colleges: 876
  },
  {
    id: 4,
    img: flag4,
    alt: "College in Australia",
    location: "Australia",
    cost: "32.09 K USD / Year",
    colleges: 109
  },
  {
    id: 5,
    img: flag5,
    alt: "College in Germany",
    location: "Germany",
    cost: "19.94 K USD / Year",
    colleges: 1274
  },
  {
    id: 6,
    img: flag6,
    alt: "College in Ireland",
    location: "Ireland",
    cost: "22.14 K USD / Year",
    colleges: 127
  },
  {
    id: 7,
    img: flag7,
    alt: "College in New Zealand",
    location: "New Zealand",
    cost: "90.14 K USD / Year",
    colleges: 909
  },
  {
    id: 8,
    img: flag2,
    alt: "College in UK",
    location: "UK",
    cost: "22.14 K USD / Year",
    colleges: 1274
  },
]
const testimonials = [
  {
    id: 1,
    img: testimonialImg1,
    alt: "What Student Says",
    quote: "I was puzzled as to which college is best for me for management course as there are too many and everyone claims to be the best suited. However, through proftcode, I could compare colleges and select which one is the best and after selecting JLU,I can confidently say that, it's one of the best decision to use the proftcode for college selection, as it gave honest inisghts about JLU and made everything simpler and better.",
    name: "Samiksha Sharma",
    collegeName: "AIIMS"
  },
  {
    id: 2,
    img: testimonialImg2,
    alt: "What Student Says",
    quote: "I was puzzled as to which college is best for me for management course as there are too many and everyone claims to be the best suited. However, through proftcode, I could compare colleges and select which one is the best and after selecting JLU,I can confidently say that, it's one of the best decision to use the proftcode for college selection, as it gave honest inisghts about JLU and made everything simpler and better.",
    name: "Kriti Mishra",
    collegeName: "JNU"
  },
  {
    id: 3,
    img: testimonialImg1,
    alt: "What Student Says",
    quote: "I was puzzled as to which college is best for me for management course as there are too many and everyone claims to be the best suited. However, through proftcode, I could compare colleges and select which one is the best and after selecting JLU,I can confidently say that, it's one of the best decision to use the proftcode for college selection, as it gave honest inisghts about JLU and made everything simpler and better.",
    name: "Samiksha Sharma",
    collegeName: "AIIMS"
  },
  {
    id: 4,
    img: testimonialImg2,
    alt: "What Student Says",
    quote: "I was puzzled as to which college is best for me for management course as there are too many and everyone claims to be the best suited. However, through proftcode, I could compare colleges and select which one is the best and after selecting JLU,I can confidently say that, it's one of the best decision to use the proftcode for college selection, as it gave honest inisghts about JLU and made everything simpler and better.",
    name: "Kriti Mishra",
    collegeName: "JNU"
  },
]
const collegeDetails = {
  id: 1,
  clgImage: collegeDetailsImg1,
  clgLogo: collegeDetailslogo1,
  collegeName: "AMITY University, Mumbai",
  location: "Mumbai (Maharashtra)",
  approvedBy: "UGC, AIU, Government of Maharashtra",
  rating: 3.5,
  reviews: 10,
  collegeType: "Private",
  established: "2002",
  feeRange: "INR 42K - 6 Lacs",
  offeredCourses: 124,
  overview: "Established in 2014, Amity University Mumbai (AUM) has been consistently ranked India's number 1 not-for-profit private university. The university has 400+ distinguished faculty, scientists and staff who ensure that only the ideal way of teaching is implemented in all the departments. AUM has been recognised by UGC and approved by NAAC with ‘A+ Grade’ and AICTE. The university offers a number of undergraduate, postgraduate and doctoral courses in the fields of Animation, Applied Sciences, Film and Drama, Food Technology, Law, Management, Interior Design, Mass Communication, etc. Amity University Mumbai (AUM) is a second-generation Amity University Mumbai (AUM) established by the Government of India. The public technical university is ranked #591-600 in the QS World University Rankings 2022 and #220 in the QS Asian Rankings 2022. According to the NIRF Rankings 2021, The institute is ranked 8th in the engineering category and 16th overall. Currently, Amity University Mumbai (AUM) has over 3397 students enrolled, 220+ faculty members, and 40+ patents filed. In addition, the institute offers more than 50 programs in engineering, science, liberal arts, design, and management through its 17 academic departments. ",
  tableCourses: [
    {
      id: 1,
      courseName: "B.Tech",
      duration: "Duration: 4 years Specializations: CSE, AI, Engineering Science, Electrical Engineering, Mathematics & Computing, Mechanical Engineering, Civil Engineering, Chemical Engineering, Engineering Physics, Metallurgical & Materials Engineering, and Biomedical Engineering",
      eligibility: "Pass in the 12th class examination, or be in the top 20 percentiles in the 12th class examination conducted by the respective Boards For SC/ST students the qualifying marks would be 65% in the 12th class examination"
    },
    {
      id: 2,
      courseName: "M.Tech",
      duration: "Duration: 2 years Specializations: Artificial Intelligence, CSE, Civil Engineering, Electrical Engineering, Metallurgical & Materials Engineering, Biotechnology, Chemical Engineering, Biomedical Engineering, Mechanical Engineering, Data Science, and Climate Science",
      eligibility: "ass in the 12th class examination, or be in the top 20 percentiles in the 12th class examination conducted by the respective Boards For SC/ST students the qualifying marks would be 65% in the 12th class examination"
    },
    {
      id: 3,
      courseName: "M.A",
      duration: "Duration: 2 years Specialization: Development Studies",
      eligibility: "Bachelor’s Degree with a minimum of 55% marks (50% for SC/ST candidates)"
    },
    {
      id: 4,
      courseName: "Phd",
      duration: "Duration: 3 years Specialization: Biotechnology, Computer Science & Engineering, Civil Engineering, Mathematics, Electrical Engineering, Liberal Arts, Chemical Engineering, Design, Biomedical Engineering, Physics, Chemistry, Metallurgical Engineering & Materials Science, and Entrepreneurship",
      eligibility: "65% marks in Masters or 75% marks in Bachelors with a valid GATE score."
    }
  ],
  links: [
    "Amity University Mumbai Admission Process 2021", "Courses offered by Amity University Mumbai", "Amity University Mumbai Scholarships", "Amity University Mumbai Placements"
  ],
  readMore: "With over a kilometre long frontage on the Mumbai-Pune Expressway, AUM provides a 360-degree transformational set-up. It is a picturesque 30-acre state-of-the-art campus including a 5-acre modern sports complex and other facilities like food plaza, ATM, salon, medical centre and laundry within the campus along with a 2000 seater on-campus hostel facility for both girls and boys. As of today, AUM has built strategic partnerships with Tata Technologies, CII School of Logistics, RICS School of Built Environment, University of London and many others. In addition, it has also tied up with IBM, CA Technologies, Oracle, Sun Microsystems & others for skill enhancement of the students. Multiple Inter-disciplinary research centres at the university have not only appealed its students but also outsiders.  At AUM, the Corporate Resource Center (CRC) assures 100% placements by positioning AUM as a preferred university partner for internships, placements and other such campus connect activities with the corporate world. ",
  topCourses: [
    {
      id: 1,
      course: "B.Tech",
      coursesOffered: "13",
      annualFee: "INR 79.19K"
    },
    {
      id: 2,
      course: "M.Tech",
      coursesOffered: "13",
      annualFee: "INR 98K"
    },
    {
      id: 3,
      course: "LLB",
      coursesOffered: "1",
      annualFee: "INR 79.1K"
    },
    {
      id: 4,
      course: "Excecutive MBA",
      coursesOffered: "2",
      annualFee: "INR 2 Lacs"
    },
    {
      id: 5,
      course: "B.Tech",
      coursesOffered: "13",
      annualFee: "INR 79.19K"
    },
    {
      id: 6,
      course: "LLM",
      coursesOffered: "1",
      annualFee: "INR 70K"
    },
  ],
}
const collegeList = {
  degree: [
    "B.Tech (Bachelor of Technology)", 
    "M.Com (Master of Commerce)", 
    "B.Arch (Bachelor of Architecture)", 
    "M.Arch (Master of Architecture)", 
  ],
  state: ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"],
    city: ['Anjaw',
    'Changlang',
    'East Siang',
    'Baksa',
			'Barpeta',
			'Bongaigaon',
      'Araria',
			'Arwal',
			'Aurangabad',
      'Bastar',
			'Bijapur',
			'Bilaspur',
      'Ahmedabad',
			'Amreli district',
			'Anand',
			'Banaskantha',
      'Ambala',
			'Bhiwani',
			'Faridabad',
			'Fatehabad',
			'Chamba',
			'Hamirpur',
			'Kangra',
			'Kinnaur',
			'Kullu',
			'Lahaul and Spiti',
			'Mandi',
			'Shimla',
			'Sirmaur',
			'Solan',
			'Una',
      'Kulgam',
			'Leh',
			'Poonch',
			'Pulwama',
			'Rajauri',
			'Ramban',
  ],
  instituteType: [
    "Government", "Private", "Public"
  ],
  collegeBox: [
    {
      id: 1,
      logo: collegeListlogo1,
      collegeName: "Indian Institute of Technology (IIT KHARAGPUR) , Kharagpur",
      approvedBy: "UGC, AIU",
      type: "Public",
      rating: 4.5,
      reviews: 10
    },
    {
      id: 2,
      logo: collegeListlogo2,
      collegeName: "Bits Pilani-K K Birla Goa Campus (BITS GOA), Goa",
      approvedBy: "UGC, AIU",
      type: "Public",
      rating: 2.85,
      reviews: 43
    },
    {
      id: 3,
      logo: collegeListlogo3,
      collegeName: "Indian Institute of Technology (IITD) , Delhi",
      approvedBy: "AICTE",
      type: "Government",
      rating: 3,
      reviews: 19
    },
    {
      id: 4,
      logo: collegeListlogo4,
      collegeName: "Indian Institute of Technology (IIT KHARAGPUR) , Kharagpur",
      approvedBy: "UGC, AIU",
      type: "Government",
      rating: 4.85,
      reviews: 20
    },
    {
      id: 5,
      logo: collegeListlogo5,
      collegeName: "Indian Institute of Technology (IIT KHARAGPUR) , Kharagpur",
      approvedBy: "UGC, AIU",
      type: "Public",
      rating: 3.85,
      reviews: 11
    },
    {
      id: 6,
      logo: collegeListlogo6,
      collegeName: "Indian Institute of Technology (IIT KHARAGPUR) , Kharagpur",
      approvedBy: "UGC, AIU",
      type: "Private",
      rating: 3.85,
      reviews: 11
    },
    {
      id: 7,
      logo: collegeListlogo7,
      collegeName: "Indian Institute of Technology (IIT KHARAGPUR) , Kharagpur",
      approvedBy: "UGC, AIU",
      type: "Public",
      rating: 3.85,
      reviews: 11
    },
  ],
}
const galleryImages = [
  {
    original: gallery1,
    thumbnail: gallery1,
  },
  {
    original: gallery2,
    thumbnail: gallery2,
  },
  {
    original: gallery3,
    thumbnail: gallery3,
  },
  {
    original: gallery4,
    thumbnail: gallery4,
  },
  {
    original: gallery5,
    thumbnail: gallery5,
  },
  {
    original: gallery6,
    thumbnail: gallery6,
  },
];

export { courses, colleges, counselling, goals, jobs, news, studyAbroad, testimonials, collegeDetails, collegeList, galleryImages }