import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection'
import Heading from '../components/Heading'
import img1 from "../images/about/one.png"
import img2 from "../images/about/two.png"
import img3 from "../images/about/three.png"
import img4 from "../images/about/four.png"
import CountUp from 'react-countup';
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const About = () => {
  let { pathname } = useLocation();
  const [about, setAbout] = useState()

  useEffect(() => {
      async function getData() {
        const res = await axios.get(`${window.apiURL}${pathname}`)
        setAbout(res.data.abouts)
      }
      getData();
  }, [pathname])

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "CollegeVidhyarthi | About"
  }, [])
  
  return (
    <div>
        {/* <BreadcrumbSection img={aboutImg} activePage="about" pageTitle="about us" /> */}
        <BreadcrumbSection img='' activePage="about" pageTitle="about us" />
        <section className="about-section">
          <Container fluid>
            {
              about && about.map((data)=>{
                return(
            <Row className='mt-40' key={data.about_id}>
              <Col md={7}>
                <Heading heading={data.title} />
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </Col>
              <Col md={5} className='text-center align-self-center mt-md-0 mt-4'>
              <img src={window.imgURL + "//about/" + data.image} alt={data.title} className='img-fluid' />
              </Col>
            </Row>
                )
              })
            }
          </Container>
          <div className="achievement-section">
            <Container fluid>
            <Heading heading="our achievements" />
              <Row>
                <Col md={3} xs={6}>
                  <div className="achievement">
                    <img src={img1} alt="Colleges" />
                    <h3><CountUp className='wow' start={0} end={2500}  duration={2.75} /></h3>
                    <h6>Colleges</h6>
                  </div>
                </Col>
                <Col md={3} xs={6}>
                  <div className="achievement">
                    <img src={img2} alt="Colleges" />
                    <h3><CountUp className='wow' start={0} end={1900}  duration={2.75} /></h3>
                    <h6>Exams</h6>
                  </div>
                </Col>
                <Col md={3} xs={6} className='mt-md-0 mt-4'>
                  <div className="achievement">
                    <img src={img3} alt="Colleges" />
                    <h3><CountUp className='wow' start={0} end={1200}  duration={2.75} /></h3>
                    <h6>monthly visits</h6>
                  </div>
                </Col>
                <Col md={3} xs={6} className='mt-md-0 mt-4'>
                  <div className="achievement">
                    <img src={img4} alt="Colleges" />
                    <h3><CountUp className='wow' start={0} end={43000}  duration={2.75} /></h3>
                    <h6>cups of coffee consumed</h6>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
    </div>
  )
}

export default About
