import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Container, Col, Row } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection';
import { useLocation, useNavigate } from 'react-router-dom'
import CommonSection from '../components/CommonSection'
import loadingImg from '../images/loading.png';

const SingleBlog = () => {
  let navigate = useNavigate();
  let { pathname } = useLocation();
  const [blogDetails, setBlogDetails] = useState()

  useEffect(() => {
    axios.get(`${window.apiURL}${pathname}`)
    .then((res)=>{
      setBlogDetails(res.data.blog)
    })
    .catch(err => {
      navigate('/')
    })
      // async function getData() {
      //   const res = await axios.get(`${window.apiURL}${pathname}`)
      //   setBlogDetails(res.data.blog)
      // }
      // getData();
  }, [pathname])

  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])

  const {
    blog_id,
    image,
    title,
    author,
    date,
    description
  } = blogDetails || {};

  useEffect(() => {
    document.title = `CollegeVidhyarthi | ${title}`
   }, [title]);

  return (
    blogDetails ?
  <div>
    <BreadcrumbSection img='' activePage={title} pageTitle={title} extraUrlText={"Blog"} extraUrl={"blog"} />
    <section className="singleBlog-section pb-40">
      <Container fluid>
        <Row key={blog_id}>
          <Col lg={9}>
            <div className="left-news-detail">
              {
                image && 
            <img src={window.imgURL + "//blog/" + image} alt="" className='img-fluid' />
              }
            <div className="blog-content">
              <h5> {title} </h5>
              <div className='d-flex'>
                <div className="author">{author}</div>
                <div className="date">{date}</div>
              </div>
              <hr />
              <div className="blog-description">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            </div>
            </div>
          </Col>
          <Col lg={3} className='d-lg-block d-none'>
            <CommonSection />
          </Col>
        </Row>
      </Container>
    </section>
  </div>
   : <div className='loading'>
   <img src={loadingImg} alt='Loading...' />
 </div>
  )
};

export default SingleBlog;
