import axios from 'axios';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { FaGraduationCap } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';

const CommonSection = () => {
  let navigate = useNavigate();
  const [college, setCollege] = useState('');

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/top-colleges`);
      setCollege(res.data.college);
    }
    getData();
  }, []);

  return (
    <div className='common-right-section'>
      <div className="box1">
        <h5> <FaGraduationCap /> Top Colleges &amp; Universities</h5>
        <ul>
          {
            college && college.map((college) => {
              return (
                <li key={college.college_name}>
                  <Button onClick={() => { navigate('/college/'+college.college_slug, { state: {slug: college.college_slug} }) }} >{college.college_name}</Button>
                  {/* <Link to="">{college.college_name}</Link> */}
                </li>
              )
            })
          }
        </ul>
      </div>
      {/* <div className="box2 mt-5">
        <h5> <FaGraduationCap /> Admission Updates for 2022</h5>
        <ul>
          <li>
            <div>
            <h6>Chandigarh University</h6>
            <div className='location-clg'><FaMapMarkerAlt />Chandigarh</div>
            </div>
            <a className="primary-btn">Apply Now</Link>
          </li>
          <li>
            <div>
            <h6>Lovely Professional University</h6>
            <div className='location-clg'><FaMapMarkerAlt />Phagwara</div>
            </div>
            <a className="primary-btn">Apply Now</Link>
          </li>
          <li>
            <div>
            <h6>K.R. Mangalam University</h6>
            <div className='location-clg'><FaMapMarkerAlt />Gurgaon</div>
            </div>
            <a className="primary-btn">Apply Now</Link>
          </li>
          <li>
            <div>
            <h6>Sanskriti University</h6>
            <div className='location-clg'><FaMapMarkerAlt />Mathura</div>
            </div>
            <a className="primary-btn">Apply Now</Link>
          </li>
          <li>
            <div>
            <h6>Inveris University</h6>
            <div className='location-clg'><FaMapMarkerAlt />Barelly</div>
            </div>
            <a className="primary-btn">Apply Now</Link>
          </li>
        </ul>
      </div> */}
    </div>
  )
}

export default CommonSection
