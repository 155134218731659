import React, { useEffect } from 'react'
import Colleges from '../components/Colleges'
import Counselling from '../components/Counselling'
// import ExploreCourses from '../components/ExploreCourses'
import Features from '../components/Features'
import Goals from '../components/Goals'
import News from '../components/News'
import SearchSection from '../components/SearchSection'
import StudyAbroad from '../components/StudyAbroad'
import Testimonials from '../components/Testimonials'
import Whyus from '../components/Whyus'

const Home = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "Welcome to CollegeVidhyarthi"
  }, [])

  return (
    <div>
      <SearchSection />
      <Features />
      <Goals />
      <Counselling />
      <Whyus />
      <Colleges />
      <StudyAbroad />
      {/* <ExploreCourses /> */}
      <News />
      <Testimonials />
    </div>
  )
}

export default Home
