import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import featureOne from "../images/features/one.png"
import featureTwo from "../images/features/two.png"
import featureThree from "../images/features/three.png"
import featureFour from "../images/features/four.png"
import featureFive from "../images/features/five.png"
import featureSix from "../images/features/six.png"

const Features = () => {
  return (
    <section className='feature-section mb-60'>
      <Container fluid>
        <div className="feature-row">
      <Row className='g-0'>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureOne} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>Explore Courses, Exams &amp; more</h5>
          </div>
        </Col>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureTwo} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>Free Counselling By Experts</h5>
          </div>
        </Col>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureThree} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>Common Application Form</h5>
          </div>
        </Col>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureFour} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>0% Interest Education Loan</h5>
          </div>
        </Col>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureFive} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>Study Abroad</h5>
          </div>
        </Col>
        <Col lg={2} md={3} sm={4} xs={6}>
          <div className="feature-box">
            <img src={featureSix} alt="Explore Courses | Proft" className="img-fluid" />
            <h5>Insurance</h5>
          </div>
        </Col>
      </Row>
      </div>
      </Container>
    </section>
  )
}

export default Features
