import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SingleDetail from './SingleDetail';

const ProfileTab = () => {

  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [basicDetail, setBasicDetail] = useState(false);
  const [contactDetail, setContactDetail] = useState(false);
  const [educationDetail, setEducationDetail] = useState(false);

  const handleSelect = (e) => {
    setSelectedCategory(e.target.value)
  }

  const showBasicDetail = (e) => {
    e.preventDefault();
    setBasicDetail(true)
  };
  const showContactDetail = (e) => {
    e.preventDefault();
    setContactDetail(true)
  };
  const showEducationDetail = (e) => {
    e.preventDefault();
    setEducationDetail(true)
  };
  const hideBasicDetail = () => setBasicDetail(false);
  const hideContactDetail = () => setContactDetail(false);
  const hideEducationDetail = () => setEducationDetail(false);

  return <div>
    <div className="right-tab-heading">
      <h4>Basic Details</h4>
      <Link to="" onClick={(e) => showBasicDetail(e)}>
        <FaPen />
      </Link>
    </div>
    <div className="detail-block">
      <Row>
        <Col lg={6}>
          <SingleDetail title="Full Name" desc="Vishal Malhotra" />
          <SingleDetail title="Gaurdian's Name" desc="Prakash Malhotra" />
          <SingleDetail title="DOB" desc="12/04/2000" />
        </Col>
        <Col lg={6}>
          <SingleDetail title="Gender" desc="Male" />
          <SingleDetail title="Category" desc="General" />
        </Col>
      </Row>
    </div>
    <div className="right-tab-heading mt-5">
      <h4>Contact Details</h4>
      <Link to="" onClick={(e) => showContactDetail(e)}>
        <FaPen />
      </Link>
    </div>
    <div className="detail-block">
      <Row>
        <Col lg={6}>
          <SingleDetail title="Mobile No." desc="9427832748" />
          <SingleDetail title="Country" desc="India" />
          <SingleDetail title="City" desc="Jaipur" />
        </Col>
        <Col>
          <SingleDetail title="Email Address" desc="vishal1234@gmail.com" />
          <SingleDetail title="State" desc="Rajasthan" />
          <SingleDetail title="Pin Code" desc="302020" />
        </Col>
      </Row>
    </div>
    <div className="right-tab-heading mt-5">
      <h4>Education Details</h4>
      <Link to="" onClick={(e) => showEducationDetail(e)}>
        <FaPen />
      </Link>
    </div>
    <div className="detail-block">
      <Row>
        <Col lg={6}>
          <h6>10th</h6>
          <SingleDetail title="Passing Year" desc="2017" />
          <SingleDetail title="Board" desc="State Board" />
          <SingleDetail title="Percentage/CGPA" desc="90.0" />
          <hr />
        </Col>
        <Col lg={6} className='mt-lg-0 mt-2'>
          <h6>12th</h6>
          <SingleDetail title="Passing Year" desc="2019" />
          <SingleDetail title="Board" desc="State Board" />
          <SingleDetail title="Percentage/CGPA" desc="92.0" />
          <hr />
        </Col>
        <Col lg={6} className='mt-2'>
          <h6>UG</h6>
          <SingleDetail title="Passing Year" desc="2022" />
          <SingleDetail title="University" desc="University of Rajasthan" />
          <SingleDetail title="Percentage/CGPA" desc="85.0" />
          <SingleDetail title="Stream" desc="Information Technology" />
        </Col>
      </Row>
    </div>
    <Modal className='common-profile-modal' show={basicDetail} onHide={hideBasicDetail} size="xl" id="basic-detail-modal">
      <Modal.Body>
        <Button className='close-btn' onClick={hideBasicDetail}>
          <FaTimes />
        </Button>
        <div className="pro-heading">
          <h4>Basic Details</h4>
        </div>
        <Form className='mt-4'>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <input type="text" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Guardian's Name</Form.Label>
                <input type="text" />
              </Form.Group>
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <select value={selectedCategory} onChange={handleSelect}>
                      <option value="General">General</option>
                      <option value="OBC">OBC</option>
                      <option value="SC">SC</option>
                      <option value="ST">ST</option>
                      <option value="Others">Others</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label>DOB</Form.Label>
                    <input type="text" placeholder="mm/dd/yy" />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`}>
                    <Form.Check
                      inline
                      label="Male"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-end">
            <input type="submit" value="Save" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
    <Modal className='common-profile-modal' show={contactDetail} onHide={hideContactDetail} size="xl" id="basic-detail-modal">
      <Modal.Body>
        <Button className='close-btn' onClick={hideContactDetail}>
          <FaTimes />
        </Button>
        <div className="pro-heading">
          <h4>Contact Details</h4>
        </div>
        <Form className='mt-4'>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Mobile No.</Form.Label>
                <input type="tel" max={10} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <input type="mail" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <input type="text" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <input type="text" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <input type="text" />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Pincode</Form.Label>
                <input type="number" />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-end">
            <input type="submit" value="Save" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
    <Modal className='common-profile-modal' show={educationDetail} onHide={hideEducationDetail} size="xl" id="basic-detail-modal">
      <Modal.Body>
        <Button className='close-btn' onClick={hideEducationDetail}>
          <FaTimes />
        </Button>
        <div className="pro-heading">
          <h4>Education Details</h4>
        </div>
        <Form className='mt-4'>
          <Row>
            <Col lg={12}>
              <Row>
                <Col xl={1}>
                  <div className="text-primary font-16 fw-bold mb-xl-0 mb-3">10th</div>
                </Col>
                <Col xl={11}>
                  <Row>
                    <Col lg={4} md={6}>
                      <Form.Group>
                        <Form.Label>Passing Year</Form.Label>
                        <input type="tel" max={10} />
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                      <Form.Group>
                        <Form.Label>Board</Form.Label>
                        <input type="mail" />
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                      <Form.Group>
                        <Form.Label>Percentage/CGPA</Form.Label>
                        <input type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row>
                <Col xl={1}>
                  <div className="text-primary font-16 fw-bold mb-xl-0 mb-3">12th</div>
                </Col>
                <Col xl={11}>
                  <Row>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Passing Year</Form.Label>
                        <input type="tel" max={10} />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Board</Form.Label>
                        <input type="mail" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Percentage/CGPA</Form.Label>
                        <input type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Stream</Form.Label>
                        <input type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row>
                <Col xl={1}>
                  <div className="text-primary font-16 fw-bold mb-xl-0 mb-3">UG</div>
                </Col>
                <Col xl={11}>
                  <Row>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Passing Year</Form.Label>
                        <input type="tel" max={10} />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>University</Form.Label>
                        <input type="mail" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Percentage/CGPA</Form.Label>
                        <input type="text" />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6}>
                      <Form.Group>
                        <Form.Label>Stream</Form.Label>
                        <input type="text" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="" className='secondary-btn'>Add More +</Link>
          <input type="submit" value="Save" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  </div>;
};

export default ProfileTab;
