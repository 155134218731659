import React, { useState } from 'react'
import { Col, Container, Modal, Row, Button } from 'react-bootstrap'
import Heading from './Heading'
import {counselling} from "../data"
import { FaPlay, FaTimes } from 'react-icons/fa'
import Slider from 'react-slick'

const Counselling = () => {

  const [show, setShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };



  const handleClose = () => setShow(false);
  const handleShow = (url) => {
    setShow(true)
    setVideoUrl(url)
  };

  return (
    <div>
      <section className='counselling-section mb-60'>
        <Container fluid>
          <Heading heading="End-to-end free counselling" />
          <p className='fw-600 text-center'>At proftcode, academic experts help you evaluate your career and course choices accurately while taking into account your educational
            background, strengths &amp; skills. From shortlisting the best colleges to tracking your entire admission process, the counselling by our experts
            will make your higher education journey hassle-free and put you on the path of success.</p>
          <Row className='align-items-center'>
            <Col md={6}>
              <Slider {...settings} className='counselling-carousel'>
                {
                  counselling.map((item) => {
                    return (
                      <div key={item.id}>
                        <img src={item.overlayImg} alt={item.alt} className='img-fluid' />
                        <button className='play-btn' onClick={() => handleShow(item.url)}>
                          <FaPlay />
                        </button>
                      </div>
                    )
                  })
                }
              </Slider>
            </Col>
            <Col md={6}>
              <h4>How it Works?</h4>
              <ul className="goals-list">
                <li>Tell us about your College / Course &smp; Location preferences.</li>
                <li>An Expert Counsellor will be assigned to you.</li>
                <li>Discuss your options with your counsellor.</li>
                <li>Apply online through our COMMON APPLICATION FORM platform.</li>
                <li>Tell us about your College / Course &amp; Location preferences.</li>
                <li>An Expert Counsellor will be assigned to you.</li>
                <li>Discuss your options with your counsellor.</li>
                <li>Apply online through our COMMON APPLICATION FORM platform.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose} size="lg" id="counselling-modal">
          <Modal.Body>
            <Button className='close-btn' onClick={handleClose}>
              <FaTimes />
            </Button>
            <iframe  src={videoUrl} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  )
}

export default Counselling