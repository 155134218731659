import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import Heading from './Heading';

const Testimonials = () => {
  const [testimonial, setTestimonial] = useState()

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/testimonial`)
      setTestimonial(res.data.testimonials)
    }
    getData();
  }, [])

  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className='testimonial-section mb-60'>
      <Container fluid>
        <Heading heading="testimonials" />
        <Slider {...settings} className='testimonial-slider'>
          {
            testimonial && testimonial.map((item) => {
              return (
                <div key={item.testimonials_id}>
                  <div className="testimonial-block">
                    <div className="img-sec">
                    <img src={window.imgURL + "/home/testimonials/" + item.image} alt="" />
                    </div>
                    <p>{item.description}</p>
                    <h5>{item.name} - {item.company_name}</h5>
                  </div>
                </div>
              )
            })
          }
        </Slider>
      </Container>
    </section>
  )
}

export default Testimonials
