import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import Heading from './Heading';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Colleges = () => {
  const [topColleges, setTopColleges] = useState();

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/top-colleges`);
      setTopColleges(res.data.college);
    }
    getData();
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='top-college-section mb-60'>
      <Container fluid>
        <Heading heading="Top colleges / universities" />
        <Slider {...settings} className='college-carousel'>
          {
            topColleges && topColleges.map((item) => {
              return (
                <div key={item.college_id}>
                  <Link to={"college/" + item.college_slug} state={{ slug: item.college_slug }}>
                  <div className="college_block">
                    <div className='img-box'>
                      <img
                        src={window.imgURL + '//bannerimage/' + item.banner_image}
                        alt={item.college_name} className='img-fluid'
                      />
                    </div>
                    <div className="college-desc">
                      <div className="college-logo">
                        <img
                          src={window.imgURL + '//collegelogo/' + item.college_logo}
                          alt={item.college_name}
                        />
                      </div>
                      <div>
                        <h5>{item.college_name}</h5>
                        <h6>{item.university_name}</h6>
                        {/* <h6>{item.location}</h6> */}
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              )
            })
          }
        </Slider>
      </Container>
    </section>
  )
}

export default Colleges
