import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Col, Container, Row } from 'react-bootstrap'
import logo from "../images/footer-logo.png"
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter, FaYoutube } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
  let { pathname } = useLocation();

  // const location = useLocation()
  // const { slug } = location.state
  const [footerLinks, setFooterLinks] = useState()

  useEffect(() => {
    async function getData() {
      // const res = await axios.get(`https://mycollege.proftcode.in/api/news/${slug}`)
      const res = await axios.get(`${window.apiURL}/footerLink`)
      setFooterLinks(res.data.link)
    }
    getData();
  }, [pathname])

  return (
    <footer className='main-footer'>
      <Container fluid>
        <Row>
          <Col lg={4}>
            <div className="footer-logo">
              <Link to='/'>
                <img src={logo} alt="College Vidhyarthi" />
              </Link>
            </div>
            <div className="contact">
              <a href="http://maps.google.com/?q=1200 Jaipur">
                <Row>
                  <Col xs={1}>
                    <FaMapMarkerAlt />
                  </Col>
                  <Col xs={11}>
                    Head Office - Jaipur<br />
                    Branch Office - Kota
                  </Col>
                </Row>
              </a>
              <a href="tel:9549920000">
                <Row>
                  <Col xs={1}>
                    <FaPhoneAlt />
                  </Col>
                  <Col xs={11}>
                    9549920000
                  </Col>
                </Row>
              </a>
              <a href="mailto:infocollegevidhyarthi@gmail.com">
                <Row>
                  <Col xs={1}>
                    <FaEnvelope />
                  </Col>
                  <Col xs={11}>
                    infocollegevidhyarthi@gmail.com
                  </Col>
                </Row>
              </a>
            </div>
          </Col>
          <Col lg={8} className='mt-lg-0 mt-5'>
            <Row>
              <Col>
                <div className='footer-list'>
                  <h5>Know Us</h5>
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <div className="footer-list">
                  <h5>Trending Links</h5>
                  <Row>
                    {
                      footerLinks && footerLinks.map((links) => {
                        return (
                          <Col md={6} key={links.footer_id}>
                            <a href={links.link}>{links.title}</a>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <ul className="social-links">
          <li>
            <Link to=""><FaFacebookF /></Link>
          </li>
          <li>
            <Link to=""><FaLinkedinIn /></Link>
          </li>
          <li>
            <Link to=""><FaTwitter /></Link>
          </li>
          <li>
            <Link to=""><FaYoutube /></Link>
          </li>
          <li>
            <Link to=""><FaInstagram /></Link>
          </li>
        </ul>
        <hr />
        <div className="d-sm-flex align-items-center justify-content-between footer-bottom text-sm-start text-center">
          <div>© 2022 <Link to="/">collegevidhyarthi</Link> All Rights Reserved</div>
          <div className='mt-sm-0 mt-3'><Link to="terms">Terms &amp; Conditions</Link></div>
        </div>
        <div className="sticky-social">
          <div><a href="https://api.whatsapp.com/send/?phone=919549920000&amp;text&amp;type=phone_number&amp;app_absent=0"><i className="fab fa-whatsapp"></i></a></div>
          <div><a href="tel:919549920000"><i className="fa fa-phone"></i></a></div>
        </div>
      </Container>
    </footer>
  )
}


export default Footer
