import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Col, Container, Row } from 'react-bootstrap'
import Heading from './Heading';
import img from "../images/goals/right.jpg"
import { Link } from 'react-router-dom';

const Goals = () => {
  const [goals, setGoals] = useState()

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/stream`)
      setGoals(res.data.stream)
    }
    getData();
  }, [])

  return (
    <section className='goals-section mb-60'>
      <Container fluid>
        <Heading heading="Select your study goals" />
        <Row>
          <Col lg={7}>
            <div className='goalsList'>
              {goals && goals.map((item) => {
                return (
                  <div className='goalBox' key={item.stream_id}>
                    <Link to={"stream/" + item.stream_slug} state={{ slug: item.stream_slug }} className="goal-block">
                      <div className="title--block">
                      <div className="img-box">
                        <img src={window.imgURL + "/stream/" + item.stream_icon} alt={item.stream_title} />
                      </div>
                      <div className="goalTitle">
                        <h5>{item.stream_title}</h5>
                      </div>
                      </div>
                      <div className='goals-courses'>
                        <p>
                        {
                        item.course.map((data, index) => {
                          return (
                            <span key={data.course_id}>{ (index ? '| ' : '') + data.course_name }</span>
                          )
                        })
                      }
                          </p>
                      </div>
                      {/* <div className='goals-courses'>
                        <p>MBA | PGDA | BMS | BCA | MMM | M.Tech | B.Tech | M.Des | B.Des</p>
                      </div> */}
                    </Link>
                  </div>
                )
              })}
            </div>
          </Col>
          <Col lg={5} className="d-lg-block d-none">
            <img src={img} alt="Goals" className='img-fluid goals--img' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Goals
