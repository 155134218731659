import React, { useEffect } from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { FaCamera, FaEdit, FaUserAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BreadcrumbSection from '../components/BreadcrumbSection'
import ProfileTab from '../components/ProfileTab';
import ReviewTab from '../components/ReviewTab';
import user from "../images/profile/user.png"

const Profile = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "CollegeVidhyarthi | Profile"
  }, [])
  
  return <div>
    {/* <BreadcrumbSection img={galleryImg} activePage="Profile" pageTitle="My Profile" /> */}
    <BreadcrumbSection img='' activePage="Profile" pageTitle="My Profile" />
    <section className="profile-section">
      <Container fluid>
      <Tab.Container id="profile-left-tabs" defaultActiveKey="profile">
        <Row>
          <Col lg={3} md={4}>
            <div className="profile-left-section">
              <div className="left_profile_set">
              <div className='profile-image'>
                <img src={user} alt="User" className='img-fluid' />
              </div>
              <Link to="" className="edit-profile">
                <FaCamera />
                <input type="file" />
              </Link>
              </div>
              <div className="profile-details">
                <h4 className='font-16 fw-bold'>Vishal Malhotra</h4>
                <div>vishal1234@gmail.com</div>
              </div>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="profile"> <FaUserAlt /> Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="review"> <FaEdit /> Review</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
          <Col lg={9} md={8} className='mt-md-0 mt-4'>
            <div className="profile-right-section">
            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <ProfileTab />
              </Tab.Pane>
              <Tab.Pane eventKey="review">
                <ReviewTab />
              </Tab.Pane>
            </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
      </Container>
    </section>
  </div>
};

export default Profile;
