import React from 'react'

const Heading = ({heading}) => {
  return (
    <div className='common-heading'>
      <h2>
      {heading}
      </h2>
    </div>
  )
}

export default Heading
