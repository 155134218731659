import './App.css';
import './responsive.css';
import Header from './components/Header';
import MainNavbar from './components/MainNavbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Contact from './pages/Contact';
import About from './pages/About';
import Career from './pages/Career';
import Gallery from './pages/Gallery';
import CollegeDetails from './pages/CollegeDetails';
import CollegeList from './pages/CollegeList';
import Profile from './pages/Profile';
import Blog from './pages/Blog';
import News from './pages/News';
import SingleNews from './pages/SingleNews';
import SingleBlog from './pages/SingleBlog';
import Course from './pages/Course';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
window.imgURL = "https://mycollege.proftcode.in/public/upload"
window.apiURL = "https://mycollege.proftcode.in/api"

function App() {


  return (
    <div className="App">
      <Header />
      <MainNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="career" element={<Career />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="college/:slug" element={<CollegeDetails />} />
        <Route path="college" element={<CollegeList />} />
        <Route path="stream/:slug" element={<CollegeList />} />
        <Route path="course/:slug" element={<CollegeList />} />
        <Route path="search" element={<CollegeList />} />
        <Route path="btech" element={<Course />} />
        <Route path="profile" element={<Profile />} />
        <Route path="blog" element={<Blog />} />
        <Route path="news" element={<News />} />
        <Route path="news/:slug" element={<SingleNews />} />
        <Route path="blog/:slug" element={<SingleBlog />} />
        <Route path="terms" element={<Terms />} />
        <Route path=":slug/:slug" element={<CollegeList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
