import React from 'react';
import { Link } from 'react-router-dom';
import noData from "../images/profile/no-data.png"

const ReviewTab = () => {
  return <div>
    <div className="text-center">
      <h3 className='text-primary mb-4'>Manage your Reviews</h3>
      <p className='font-16 light-text'>Nothing to show yet. You haven't written any review yet.</p>
      <img src={noData} alt="No data yet" />
      <div className='mt-4'>
        <Link to="" className='primary-btn'>Write a Review</Link>
      </div>
    </div>
  </div>;
};

export default ReviewTab;
