import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import defaultImg from "../images/breadcrumb/default.jpg"
import { FaHome } from 'react-icons/fa'

const BreadcrumbSection = ({ img, pageTitle, activePage, extraUrlText, extraUrl }) => {

  const defaultImage = {
    backgroundImage: `url(${ defaultImg })`,
  }
  const currentImage = {
    backgroundImage: `url(${ img })`,
  }

  const styledSection =  img ? currentImage : defaultImage 
  // const styledSection =  defaultImage 

  return (
    <div className='breadcrumb-section' style={styledSection}>
          {
            pageTitle !== "Not Found" ?
      <div className="breadcrumb-content">
            <h3>{pageTitle}</h3>
            <Breadcrumb>
            <li><Link to="/"><FaHome /></Link></li>
            {
              extraUrl &&
              <li><Link to={"/"+extraUrl}>{extraUrlText}</Link></li>
            }
            <li className='active'>{activePage}</li>
          </Breadcrumb>
      </div> : ''
          }
    </div>
  )
}

export default BreadcrumbSection
