import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { FaPhoneAlt, FaEnvelope, FaTimes } from "react-icons/fa"
import { Link } from 'react-router-dom';

const Header = () => {

  const [show, setShow] = useState(false);
  const [loginModal, setLoginModal] = useState(true);
  const [signupModal, setSignupModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = (e) => {
  //   e.preventDefault();
  //   setShow(true)
  // };
  const handleLoginModal = (e) => {
    e.preventDefault();
    setLoginModal(!loginModal)
    setSignupModal(false)
    setForgetModal(false)
  };
  const handleSignupModal = (e) => {
    e.preventDefault();
    setSignupModal(!signupModal)
    setLoginModal(false)
    setForgetModal(false)
  };
  const handleForgetModal = (e) => {
    e.preventDefault();
    setForgetModal(!forgetModal)
    setSignupModal(false)
    setLoginModal(false)
  };

  return (
    <header>
      <Container fluid>
        <Row className='align-items-center'>
          <Col md={6} className='d-md-block d-none'>
            <div className='fw-bold'>
              Welcome to College Vidhyarthi!
            </div>
          </Col>
          <Col md={6}>
            <ul className='d-sm-flex justify-content-end text-sm-start text-center'>
              <li className='me-4'>
                <a href="tel:+91 9549920000">
                  <FaPhoneAlt className='header-icons' /> +91 9549920000
                </a>
              </li>
              <li className='mt-sm-0 mt-2'>
                <a href="mailTo:infocollegevidhyarthi@gmail.com">
                  <FaEnvelope className='header-icons' /> infocollegevidhyarthi@gmail.com
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
      </Container>
      <Modal show={show} onHide={handleClose} size="lg" id="loginModal">
        <Modal.Body>
          <Button className='close-btn' onClick={handleClose}>
            <FaTimes />
          </Button>
          <Row>
            <Col lg={5} className='left-section d-lg-block d-none'>
              <h5>Why Sign Up?</h5>
              <hr />
              <ul>
                <li>View College Brochures</li>
                <li>Check Detailed fees</li>
                <li>Shortlist and Apply to Colleges</li>
                <li>Ask Questions to senior Counselors </li>
                <li>Never miss Important deadlines</li>
              </ul>
            </Col>
            <Col lg={7}>
              <div className="form-content">
              {loginModal ? <div className="login-section">
                <h4>Login</h4>
                <form action="">
                  <input type="text" placeholder='Email or Mobile Number' />
                  <input type="password" placeholder='Password' />
                <Link to="" className="fogetPassword d-block text-end primary-color" onClick={(e) => handleForgetModal(e)}>Forgot password?</Link>
                <input type="submit" value="Sign In" className='mt-2' />
                <div className='text-center'>New to Proftcode? <Link to="" onClick={(e) => handleSignupModal(e)}className='fw-bold'>Signup</Link></div>
                </form>
              </div> : ''}
              {signupModal ? <div className="signup-section">
                <h4>Signup</h4>
                <form action="">
                  <input type="text" placeholder='Name' />
                  <input type="email" placeholder='Email' />
                  <input type="text" placeholder='Phone Number' />
                  <input type="text" placeholder='City' />
                  <input type="text" placeholder='Course' />
                <input type="submit" value="Request OTP" />
                <div>Already a member? <Link to="" onClick={(e) => handleLoginModal(e)}className='fw-bold'>Login</Link></div>
                </form>
              </div> : ''}
              {forgetModal? <div className="forget-section">
                <h4>Forget Password</h4>
                <p>Enter the mobile no. associated with Proftcode, we'll send you an OTP allowing you to reset your password</p>
                <form action="">
                  <input type="text" placeholder='Email or Mobile Number' />
                <input type="submit" value="Generate OTP" />
                <div className='text-center'><Link to="" onClick={(e) => handleLoginModal(e)}className='fw-bold'>Login</Link> / <Link to="" onClick={(e) => handleSignupModal(e)}className='fw-bold'>Signup</Link></div>
                </form>
              </div> : ''}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </header>
  )
}

export default Header
