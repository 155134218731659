import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Col, Container, Form, Nav, Row, Tab, Modal, Button } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection'
import { FaArrowLeft, FaChevronRight, FaSlidersH, FaChevronDown, FaTimes } from 'react-icons/fa'
// import StarRatings from 'react-star-ratings';
import loadingImg from '../images/loading.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CollegeList = () => {

  let navigate = useNavigate();
  let { pathname, search } = useLocation();
  const location = useLocation();
  const [validated, setValidated] = useState(false);

  const [courses, setCourses] = useState();

  const [slug, setSlug] = useState({});

  const [list, setList] = useState();
  const [stream, setStream] = useState();
  const [courseType, setCourseType] = useState()

  // Mobile Filter
  const [showFilter, setShowFilter] = useState(false)

  // Loading
  const [loading, setLoading] = useState(true)

  // Filter texts
  const [courseText, setCourseText] = useState([])
  const [streamText, setStreamText] = useState([])
  const [showApply, setShowApply] = useState(false);

  // Dynamic Modal Texts
  const [applyLogo, setApplyLogo] = useState()
  const [applyClgName, setApplyClgName] = useState()

  // l1 = courseText.length and l2 = streamText.length
  const [l1, setl1] = useState(0)
  const [l2, setl2] = useState(0)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    course: '',
    enquiry_type: 'college'
  }, [applyClgName]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    courseText ? setl1(courseText.length) : setl1(0)
    streamText ? setl2(streamText.length) : setl2(0)
  }, [courseText, streamText])

  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `${window.apiURL}/course`
      )
      setCourses(res.data.course);
    }
    getData();
  }, []);

  useEffect(() => {
    let ss
    if (location.state === null) {
      setSlug('')
    }
    else {
      ss = location.state
      setSlug(Object.values(ss))
    }
  }, [location])

  useEffect(() => {
    async function getData() {
      let res
      if (l1 === 0 && l2 === 0) {
        if (pathname === `/stream/${slug}`) {
          res = await axios.get(`${window.apiURL}/stream/${slug}`);
        }
        else if (pathname === `/course/${slug}`) {
          res = await axios.get(`${window.apiURL}/course/${slug}`);
        }
        else if (pathname === `/${slug}`) {
          res = await axios.get(`${window.apiURL}/${slug}`);
        }
        else if (pathname + search === `/search?search=${slug}`) {
          res = await axios.get(`${window.apiURL}/search?search=${slug}`);
        }
        else if (pathname === `/college`){
          res = await axios.get(`${window.apiURL}/colleges`);
        }
        res.data.college.length === 0 ? setList('') : setList(res.data.college)
      }
      else if (l1 !== 0 && l2 === 0) {
        res = await axios.get(`${window.apiURL}/filterCollege?course=${courseText}`);
        res.data.college.length === 0 ? setList('') : setList(res.data.college)
      }
      else if (l2 !== 0 && l1 === 0) {
        res = await axios.get(`${window.apiURL}/filterCollege?stream=${streamText}`);
        res.data.college.length === 0 ? setList('') : setList(res.data.college)
      }
      else if (l1 !== 0 && l2 !== 0) {
        res = await axios.get(`${window.apiURL}/filterCollege?stream=${streamText}&course=${courseText}`);
        res.data.college.length === 0 ? setList('') : setList(res.data.college)
      }
      setLoading(false)
    }
    getData();
  }, [l1, l2, slug, streamText, courseText]);

  useEffect(() => {
    async function getData() {
      const res = await axios.get('https://mycollege.proftcode.in/api/stream');
      setStream(res.data.stream);
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const res = await axios.get('https://mycollege.proftcode.in/api/course');
      setCourseType(res.data.course);
    }
    getData();
  }, []);

  const searchCourse = (e) => {
    const currentvalue = e.target.value;
    const checked = e.target.checked;
    let newArr = [...courseText, e.target.value]
    if (checked) {
      setCourseText(newArr)
    }
    else {
      if (courseText.includes(currentvalue)) {
        newArr = newArr.filter(day => day !== currentvalue);
        setCourseText(newArr)
      }
    }
  }
  const searchStream = (e) => {
    const currentvalue = e.target.value;
    const checked = e.target.checked;
    let newArr = [...streamText, e.target.value]
    if (checked) {
      setStreamText(newArr)
    }
    else {
      if (streamText.includes(currentvalue)) {
        newArr = newArr.filter(day => day !== currentvalue);
        setStreamText(newArr)
      }
    }
  }

  useEffect(() => {
    try {
      axios.post("https://mycollege.proftcode.in/api/filterCollege", {
        course: courseText
      })
    }
    catch (error) {
      alert(error)
    }
  }, [courseText])

  useEffect(() => {
    try {
      axios.post("https://mycollege.proftcode.in/api/filterCollege", {
        stream: streamText
      })
    }
    catch (error) {
      alert(error)
    }
  }, [streamText])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.title = "Colleges | CollegeVidhyarthi"
  }, []);

  const handleShowFilter = e => {
    e.preventDefault();
    setShowFilter(!showFilter)
  }

  const showApplyModal = (e, college_logo, college_name) => {
    // e.preventDefault();
    setShowApply(true);
    setApplyLogo(college_logo)
    setApplyClgName(college_name)
  };

  const hideApplyModal = (e) => {
    // e.preventDefault();
    setShowApply(false);
    setApplyLogo()
    setApplyClgName()
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
      if(formData.name === '' || formData.email === '' || formData.mobile === '' || formData.city === '' || formData.state === '' || formData.course === ''){
        const notify = () => toast.error("Please provide all the fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: 'colored'
        })
        notify()
      }
      else {
        try {
          await axios.post("https://mycollege.proftcode.in/api/enquiry", {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            city: formData.city,
            state: formData.state,
            course: formData.course,
            college: applyClgName,
            enquiry_type: formData.enquiry_type
          })
          const notify = () => toast.success("Successfully Submitted", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: 'colored'
          })
          notify()
        }
        catch (error) {
          alert(error)
        }
      }
  }

  return (
    <div>
      <BreadcrumbSection img='' activePage="Colleges" pageTitle="Colleges" />
      <section className="collegeList-section">
        <Container fluid>
          <Row>
            <Col lg={3} md={4}>
              <div className={showFilter ? "filter-area show__filters d-md-none d-block" : "filter-area d-md-none d-block "}>
                <div className='d-flex align-items-center ms-3 mt-2' onClick={(e) => handleShowFilter(e)}> <FaArrowLeft /> <div className='font-16 fw-bold ms-3'>Filter</div></div>
                <Tab.Container defaultActiveKey="degree">
                  <Row>
                    <Col xs={5}>
                      <Nav variant="pills" className="flex-column filter__column">
                        <Nav.Item>
                          <Nav.Link eventKey="degree">Degree <FaChevronRight /> </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="institute-type">Institution Type <FaChevronRight /></Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col xs={7} className='ps-0'>
                      <Tab.Content>
                        <Tab.Pane eventKey="degree">
                          {
                            courseType &&
                            <div className="filter-downdown">
                              <h5 className='d-md-flex d-none justify-content-between'><span>Course</span> <FaChevronDown /> </h5>
                              <div className="filter-dropdown-content">
                                <hr className='d-md-block d-none' />
                                <ul>
                                  {
                                    courseType.map((item) =>
                                      <li key={item.course_id}>
                                        <div className='form_check'>
                                          <label>
                                            <input type="checkbox" value={item.course_id} onClick={(e) => searchCourse(e)} />
                                            <span className='checkmark'></span>
                                            <span>{item.course_name}</span>
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="institute-type">
                          {
                            stream &&
                            <div className="filter-downdown">
                              <h5 className='d-md-flex d-none justify-content-between'><span>Stream</span> <FaChevronDown /> </h5>
                              <div className="filter-dropdown-content">
                                <hr className='d-md-block d-none' />
                                <ul>
                                  {
                                    stream.map((item) =>
                                      <li key={item.stream_id}>
                                        <div className='form_check'>
                                          <label>
                                            <input type="checkbox" value={item.stream_id} onClick={(e) => searchStream(e)} />
                                            <span className='checkmark'></span>
                                            <span>{item.stream_title}</span>
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
                <div className="apply_btns">
                  <Link to="" className='secondary-btn' onClick={(e) => handleShowFilter(e)}>Close</Link>
                  <Link to="" className='secondary-btn' onClick={(e) => handleShowFilter(e)}>Submit</Link>
                </div>
              </div>
              <div className='d-md-block d-none'>
                {
                  courseType &&
                  <div className="filter-downdown">
                    <h5 className='d-md-flex d-none justify-content-between'><span>Course</span> <FaChevronDown /> </h5>
                    <div className="filter-dropdown-content">
                      <hr className='d-md-block d-none' />
                      <ul>
                        {
                          courseType.map((item) =>
                            <li key={item.course_id}>
                              <div className='form_check'>
                                <label>
                                  <input type="checkbox" value={item.course_id} onClick={(e) => searchCourse(e)} />
                                  <span className='checkmark'></span>
                                  <span>{item.course_name}</span>
                                </label>
                              </div>
                            </li>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  stream &&
                  <div className="filter-downdown">
                    <h5 className='d-md-flex d-none justify-content-between'><span>Stream</span> <FaChevronDown /> </h5>
                    <div className="filter-dropdown-content">
                      <hr className='d-md-block d-none' />
                      <ul>
                        {
                          stream.map((item) =>
                            <li key={item.stream_id}>
                              <div className='form_check'>
                                <label>
                                  <input type="checkbox" value={item.stream_id} onClick={(e) => searchStream(e)} />
                                  <span className='checkmark'></span>
                                  <span>{item.stream_title}</span>
                                </label>
                              </div>
                            </li>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                }
              </div>
            </Col>
            <Col lg={9} md={8}>
              <div className="collegeList-content">
                <div className="text-end">
                  <Link to="" id="filter_btn" className='d-md-none d-inline-block' onClick={(e) => handleShowFilter(e)}> <FaSlidersH /> Applied Filters</Link>
                </div>
                {
                  loading ? <div className='loading'><img src={loadingImg} alt="Loading..." /></div> :
                    <Row>
                      {list !== '' ? list.map((course) =>
                        <Col lg={6} key={course.college_id}>
                          <div className="courseList-box">
                            <Row className='align-items-center'>
                              <Col sm={3}>
                                <img src={window.imgURL + "//collegelogo/" + course.college_logo} alt={course.college_name} className='mx-sm-0 mx-auto d-block mb-sm-0 mb-3' />
                              </Col>
                              <Col sm={9}>
                                <h6 className="college-title">
                                  {course.college_name}
                                </h6>
                                <div className='location-details mt-md-0 mt-2'>
                                  <div className='me-4'><span className='fw-bold'>Approved by:</span> <span>{course.approve_title}</span></div>
                                  <div><span className='fw-bold'>Affiliated By:</span> <span>{course.university_name}</span></div>
                                </div>
                                {/* <div className="d-flex mt-2">
                                  <StarRatings
                                    rating={course.rating}
                                    starRatedColor="#ffc400"
                                    starEmptyColor="#d9d9d9"
                                    numberOfStars={5}
                                    starSpacing="2px"
                                  />
                                  <div className='ms-3 mt-1 review-text'>{course.review} Reviews</div>
                                </div> */}
                              </Col>
                            </Row>
                            <hr />
                            <div className='d-flex align-items-center justify-content-between location-details mt-md-0 mt-2'>
                              <div><span className='fw-bold'>Medium:</span> <span>{course.language_title}</span></div>
                              <div><span className='fw-bold'>Established In:</span> <span>{course.established_in}</span></div>
                              <div className='d-xl-block d-lg-none d-sm-block d-none'><span className='fw-bold'>Type:</span> <span>{course.college_type}</span></div>
                            </div>
                            <div className='d-xl-none d-lg-block d-sm-none d-block location-details'>
                              <div><span className='fw-bold'>Type:</span> <span>{course.college_type}</span></div>
                            </div>
                            {/* <div className='d-flex justify-content-center course-links'>
                              <Link to="">Admission 2022</Link>
                              <Link to="">Reviews</Link>
                              <Link to="">Courses &amp; Fees</Link>
                              <Link to="">Placements</Link>
                            </div> */}
                            <div className='d-sm-flex align-items-center justify-content-center mt-3'>
                              <div className='mt-md-0 mt-2 me-sm-3 me-2 d-sm-block d-inline'><Button onClick={() => { navigate('/college/' + course.college_slug, { state: { slug: course.college_slug } }) }} className='secondary-btn mb-sm-0 mb-2'>Get College Details</Button></div>
                              <div className='mt-md-0 mt-2 d-sm-block d-inline'><Link to="" className='primary-btn mb-sm-0 mb-2' onClick={e => showApplyModal(e, course.college_logo, course.college_name)}>Apply Now</Link></div>
                              {/* <div className='mt-md-0 mt-2 d-sm-block d-inline'><Link to="" className='primary-btn'>Apply Now</Link></div> */}
                            </div>
                          </div>
                        </Col>
                      ) : <><div className='no-data-block'>"Oops! No Data Found"</div></>}
                    </Row>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={showApply} onHide={hideApplyModal} size='lg' id="applyNow-modal">
        <Modal.Body>
          <Button className='close-btn' onClick={hideApplyModal}>
            <FaTimes />
          </Button>
          <div className='college-heading-block'>
            {applyLogo &&
              <img
                src={window.imgURL + '//collegelogo/' + applyLogo}
                alt={applyClgName}
              />
            }
            <div className='ms-4'>
              <h4>Register Now to Apply</h4>
              <h6>{applyClgName}</h6>
            </div>
          </div>
          <Form className='mt-4' onSubmit={handleSubmit} noValidate validated={validated}>
            <Row>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    onChange={handleChange} value={formData.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Full Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange} value={formData.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    required
                    type="text" name="mobile" placeholder='Mobile No.' onChange={handleChange} value={formData.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your Contact.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>City You live in</Form.Label>
                  <Form.Control
                    required
                    type="text" name="city" placeholder='City' onChange={handleChange} value={formData.city}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide this field.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    required
                    type="text" name="state" placeholder='State' onChange={handleChange} value={formData.state}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide this field.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group">
                  <Form.Label>Course interested in</Form.Label>
                  <select name="course" onChange={handleChange} value={formData.course} required>
                    <option value='Selected'>Select</option>
                    {
                      courses && courses.map((item) => {
                        return (
                          <option value={item.course_name} key={item.course_name}>{item.course_name}</option>
                        )
                      })
                    }
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <input type="hidden" name="enquiry_type" value={formData.enquiry_type} />
            <input type="hidden" name="college" />
            <div className='d-inline-block mt-lg-0 mt-3'>
              <input type='submit' value='Submit' />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  )
};

export default CollegeList;
