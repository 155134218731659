import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Col, Container, Row } from 'react-bootstrap'
import Heading from './Heading'

const StudyAbroad = () => {
  const [studyAbroad, setstudyAbroad] = useState();

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/studyabroad`);
      setstudyAbroad(res.data.studyabroads);
    }
    getData();
  }, []);
  
  return (
    <section className='studyAbroad-section mb-60'>
      <Container fluid>
      <Heading heading="Study abroad" />
      <p>Spread your wings, the entire world is open for you !
        ,<br />
        Here is a platform to guide and assist students interested in securing higher education abroad.</p>
      <Row className='justify-content-center'>
      {
        studyAbroad && studyAbroad.map((item) => {
          return (
            <Col  xl={3} lg={4} sm={6} key={item.country_name}>
              <div className="studyAbroad_block">
                <img src={window.imgURL + "//country/" + item.country_flag} alt="" />
                <h5>Study in {item.country_name}</h5>
                <hr />
                <div className='college-info'>
                  <div className="light">
                    No. of Colleges:
                  </div>
                  <span>{item.colleges}</span>
                </div>
                <div className='college-info'>
                  <div className="light">
                    Avg. Study Cost:
                  </div>
                  <span>{item.averagecost}</span>
                </div>
              </div>
            </Col>
          )
        })
      }
      </Row>
      </Container>
    </section>
  )
}

export default StudyAbroad
