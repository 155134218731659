import React from 'react';

const SingleDetail = ({title, desc}) => {
  return <div className="single-detail">
            <span>{title}:</span>
            <div className='text-end'>{desc}</div>
          </div>
};

export default SingleDetail;
