import React, { useEffect, useState } from 'react'
import axios from "axios"
import BreadcrumbSection from '../components/BreadcrumbSection'
import phone from '../images/contact/contact.png'
import mail from '../images/contact/mail.png'
import location from '../images/contact/location.png'
import { Col, Container, Row, Form } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [validated, setValidated] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "CollegeVidhyarthi | Contact"
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if(formData.name === '' || formData.email === '' || formData.mobile === ''){
      const notify = () => toast.error("Please provide all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: 'colored'
      })
      notify()
    }
    else {
      try {
        await axios.post(`${window.apiURL}/contactstore`, {
          name: formData.name,
          email: formData.email,
          mobileno: formData.mobile,
          message: formData.message
        })
        const notify = () => toast.success("Successfully Submitted", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: 'colored'
        })
        notify()
      }
      catch (error) {
        alert(error)
      }
    }
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value})
  }

  return (
    <div>
      {/* <BreadcrumbSection img={breadcrumbImg} activePage="Contact" pageTitle="Contact us" /> */}
      <BreadcrumbSection img='' activePage="Contact" pageTitle="Contact us" />
      <section className="contact-section">
        <Container fluid>
              <div className="contact-left">
                <Row>
                <Col md={4} className='mb-md-0 mb-4'>
                  <a href="http://maps.google.com/?q=1200 Jaipur" className="contact-block">
                        <div className="img_sec circlee1">
                          <img src={location} alt="College World" />
                        </div>
                        <div className="fw-bold font-16">Address</div>
                        <hr />
                        <span> Head Office - Jaipur<br />
                    Branch Office - Kota</span>
                  </a>
                </Col>
                <Col md={4} className='mb-md-0 mb-4'>
                  <a href="tel: 9549920000" className="contact-block">
                      <div className="img_sec circlee2">
                      <img src={phone} alt="College World" />
                    </div>
                    <div className="fw-bold font-16">Contact No.</div>
                    <hr />
                      <span>9549920000</span>
                  </a>
                </Col>
                <Col md={4}>
                  <a href="mailto: infocollegevidhyarthi@gmail.com" className="contact-block">
                      <div className="img_sec circlee3">
                      <img src={mail} alt="College World" />
                    </div>
                    <div className="fw-bold font-16">Mail</div>
                    <hr />
                      <span>infocollegevidhyarthi@gmail.com</span>
                  </a>
                </Col>
                </Row>
              </div>
              <div className="contact-form">
                <h4>Get in Touch With Us</h4>
                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required type="text" name="name" placeholder='Full Name' onChange={handleChange} value={formData.name} />
                  <Form.Control.Feedback type="invalid">
                    Please provide Full Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    onChange={handleChange} value={formData.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Control required name="mobile" placeholder='Mobile No.' onChange={handleChange} value={formData.mobile} />
                  <Form.Control.Feedback type="invalid">
                    Please provide Mobile No.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <textarea rows="3" name="message" placeholder='Message' onChange={handleChange} value={formData.message} />
                </Form.Group>
                {/* <input type="hidden" name="enquiry_type" value={formData.enquiry_type} /> */}
                <input type="submit" value="Submit" />
              </Form>
              </div>
          <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.810661309088!2d75.75811456436504!3d26.877756018066023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4f87fdcdf6d%3A0x752a034a62d4427d!2sNew%20Aatish%20Market%2C%20Shanthi%20Nagar%2C%20Mansarovar%2C%20Jaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1641899149457!5m2!1sen!2sin" width="100%" height="450" loading="lazy"></iframe>
        </Container>
      </section>
      <ToastContainer />
    </div>
  )
}

export default Contact
