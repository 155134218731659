import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import Heading from './Heading';
import {Link} from 'react-router-dom';

const News = () => {
  const [news, setNews] = useState();

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/news`);
      setNews(res.data.news);
    }
    getData();
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='news-section mb-60'>
      <Container fluid>
        <Heading heading="latest news" />
        <Slider {...settings}>
          {
            news && news.map((item) => {
              return (
                <div key={item.news_id}>
                <Link to={`/news/${item.slug}`} state={{ slug: item.slug }} className='news-block'>
                    <img src={window.imgURL + '/news/' + item.image} alt='' />
                    <div className="news-content">
                      <h6> {item.title} </h6>
                      <div className="d-flex justify-content-between">
                      <span>{item.date}</span>
                      <span> {item.author} </span>
                      </div>
                     <div className="newsShort">
                     <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                     </div>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </Slider>
      </Container>
    </section>
  )
}

export default News
