import React, { useEffect } from 'react'
import BreadcrumbSection from '../components/BreadcrumbSection'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { FaBook, FaCalendarAlt, FaGraduationCap, FaUserGraduate } from 'react-icons/fa'
import CommonSection from '../components/CommonSection'
import { Link } from 'react-router-dom'


const Course = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
    document.title = "CollegeVidhyarthi | Courses"
  }, [])

  return (
    <div>
      <BreadcrumbSection img='' activePage="B.Tech" pageTitle="B.Tech" />
      <section className="college-details-section course-page-section pb-40">
        <div className="college-details-tab course-details-section">
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div className="left-college-detail">
                      <div className="college-features">
                        <Row>
                          <Col md={3} xl={3} lg={6} sm={6}>
                            <div className="college-feature-box text-center">
                                  <FaCalendarAlt />
                                  <span className="light-text mt-2">
                                    Average Duration
                                  </span>
                                  <span>4 Years</span>
                            </div>
                          </Col>
                          <Col md={3} xl={3} lg={6} sm={6} className='mt-sm-0 mt-4'>
                            <div className="college-feature-box text-center">
                                  <FaGraduationCap />
                                  <span className="light-text mt-2">
                                    Average Fee
                                  </span>
                                  <span>50K - 1.5L INR Annual</span>
                            </div>
                          </Col>
                          <Col xl={3} lg={6} md={3} sm={6} className='mt-xl-0 mt-xl-0 mt-lg-4 mt-md-0 mt-4'>
                            <div className="college-feature-box text-center">
                                  <FaBook />
                                  <span className="light-text mt-2">
                                    Exams Accepted
                                  </span>
                                  <span>BITSAT, Jee Main</span>
                            </div>
                          </Col>
                          <Col xl={3} lg={6} md={3} sm={6} className='mt-xl-0 mt-xl-0 mt-lg-4 mt-md-0 mt-4'>
                            <div className="college-feature-box text-center">
                                  <FaUserGraduate />
                                  <span className="light-text mt-2">
                                    Study Mode
                                  </span>
                                  <span>Regular</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="about-course">
                        <h4>About Bachelor of Technology (B.Tech)</h4>
                        <p>Bachelors of Technology often referred to as B.Tech, is a four-year full-time course that cover different fields of engineering and technology. The course is designed to develop the technical skills of aspirants who wish the work in the core technology and development sector. B.Tech comprises both theoretical and practical knowledge. Students are also offered hands-on training in order to learn how different software and hardware in their respective fields are operated. Apart from the core engineering sectors, B.Tech enables candidates to work in the Information Technology, Data Analysis, Consultancy and Management sectors. With good technical skills, Bachelor of Technology graduates can also apply for jobs in various Public Sector Undertakings (PSUs) and other jobs in the government sector.</p>
                      <Table bordered className='mt-0 mb-3'>
                        <tbody>
                            <tr>
                              <td><Link to=""> MBA Admissions 2022</Link></td>
                              <td className='d-sm-table-cell d-none'><Link to=""> MBA Entrance Exams</Link></td>
                              <td className='d-sm-table-cell d-none'><Link to=""> Top MBA Specializations</Link></td>
                            </tr>
                            <tr className='d-sm-none d-table-row'>
                              <td><Link to=""> MBA Entrance Exams</Link></td>
                            </tr>
                            <tr className='d-sm-none d-table-row'>
                              <td><Link to=""> Top MBA Specializations</Link></td>
                            </tr>
                        </tbody>
                      </Table>
                      <p>Full Time MBA is the most popular type of MBA (Master of Business Administration) course pursued by students in India. The MBA Syllabus is the most market-oriented as compared to other Masters' Courses. <Link to="">MBA Subjects</Link>  include Marketing Management, Supply Chain Management, Operations Management, Strategic Management, etc. Apart from MBA full time, <Link to="">Distance MBA</Link> are also available.</p>
                      <p>
                        <span className="d-sm-flex">
                        <Link to="" className="primary-btn me-2">Apply Now</Link>
                        <Link to="" className="secondary-btn me-2 mt-sm-0 mt-2">Check Eligibility</Link>
                        <Link to="" className="primary-btn mt-sm-0 mt-2">Get Updates</Link>
                        </span>
                      </p>
                        <h4 className='mt-5'>MBA Course Details</h4>
                        <ul>
                          <li>
                          MBA Eligibility is that students must have 50% in graduation. Students from any background can study MBA after clearing <Link to="">MBA entrance exam</Link>.
                          </li>
                          <li>
                          <Link to="">MBA Admission</Link> takes place through MBA Entrance Exams. These entrance exams can be either University level or National Level entrance Exam. Some of the University Level MBA entrance exams include SNAP, NMAT or XAT. National Level MBA Entrance exams include CAT, MAT or GMAT.
                          </li>
                          <li>
                          MBA Duration depends on the type of MBA the student has chosen. Full Time MBA is 2 years, Executive MBA is 1 year, Distance MBA is 2 years which acn be extended, Online MBA is also 1 year duration.  
                          </li>
                          <li>
                          Types of MBA include Full Time 2 year MBA, Online MBA, <Link to="">Distance MBA</Link>  and Executive MBA. Students with more than 5 years of experience generally go for <Link to="">Executive MBA</Link>. Students also have the option of pursuing an <Link to="">One-Year MBA</Link>   which also requires working experience but is very different from normal executive MBA.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
        </div>
      </section>
    </div>
  )
}

export default Course
