import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Col, Container, Row, NavDropdown } from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom';
import logo from "../images/logo.png"

const MainNavbar = () => {

  const [stickyClass, setStickyClass] = useState(false);
  const [menuIcon, setMenuIcon] = useState(false)
  // const [submenu, setSubmenu] = useState(false)
  const [searchDrop, setSearchDrop] = useState(false)
  const [courses, setCourses] = useState()
  const [searchText, setSearchText] = useState('')
  let { pathname } = useLocation();
  const [menuItem, setMenuItem] = useState();

  const handleChange = (event) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/course`)
      setCourses(res.data.course)
    }
    getData();
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, [courses]);

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/menu`);
      setMenuItem(res.data.menu);
    }
    getData();
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass(true) : setStickyClass(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setMenuIcon(!menuIcon);
  }

  const handleNavLink = () => {
    setMenuIcon(!menuIcon);
  }

  const searchDropClick = (e) => {
    e.preventDefault();
    setSearchDrop(!searchDrop);
    setSearchText('')
  }

  // const handleClickDrop = (e) => {
  //   e.preventDefault();
  //   setSubmenu(!submenu);
  // }
  const searchhandle = (e) => {
    setSearchText('')
    setSearchDrop(!searchDrop);
  }

  useEffect(() => {
    setSearchDrop(false);
  }, [pathname])

  return (
    <>
      <div className={stickyClass ? 'main-navbar stick-nav' : 'main-navbar'}>
        <Container fluid>
          <Row className='align-items-center mainNav-content'>
            <Col md={2}>
              <Link to="/" className='logo'>
                <img src={logo} alt="College" />
              </Link>
            </Col>
            <Col md={10}>
              <nav className='d-lg-block d-none'>
                <ul>
                  {
                    menuItem && menuItem.map((menu) => {
                      return (
                        <li className='dropdown' key={menu.menu_id}>
                          <Link to="" onClick={(e) => e.preventDefault()}>{menu.title}</Link>
                          <div className='submenu'>
                            <div className="row g-0">
                              {
                                menu.submenu.map((data) => {
                                  return (
                                    <div className="col-4" key={data.menu_id}>
                                      <Link to={menu.slug + "/" + data.slug} state={{ slug: menu.slug + "/" + data.slug }} >{data.title}</Link>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </li>
                      )
                    })
                  }
                  <li className='dropdown'>
                    <Link to="" onClick={(e) => e.preventDefault()}>Courses</Link>
                    <div className='submenu'>
                      <div className="row g-0">
                        {
                          courses && courses.map((data) => {
                            return (
                              <div className="col-4" key={data.course_id}>
                                <Link to={"course/" + data.course_slug} state={{ slug: data.course_slug }} >{data.course_name}</Link>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/college">Colleges</Link>
                  </li>
                  {/* <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li> */}
                </ul>
              </nav>
              <Link to="" id="menuBar" className='d-lg-none d-block' onClick={(e) => handleClick(e)}>
                <div></div>
              </Link>
              <Link to="" className="search-box" onClick={(e) => searchDropClick(e)}>
                <FaSearch />
              </Link>
            </Col>
          </Row>
        </Container>
        <div className={searchDrop ? "drop-search show" : "drop-search"}>
          <h6>Search for best Colleges, Courses, Exams and Education Updates</h6>
          <div className="input__group">
            <span>
              <FaSearch />
            </span>
            <input type="text" placeholder='Enter what are you looking for: College, Specialization, Course or Exam' onChange={handleChange} value={searchText} />
            {/* <button type="submit" className='btn-primary'>Search</button> */}
            {/* <Button onClick={() => { navigate('/search' , { state: { slug: searchText } })}} className='secondary-btn'>Search</Button> */}
            <Link to={"search?search=" + searchText} state={{ slug: searchText }} onClick={searchhandle}>Search</Link>
          </div>
        </div>
      </div>
      <div className={menuIcon ? "mobile-menu show" : "mobile-menu"}>
        <div className="wrap-container">
          <div className='navbar-profile'>
            <Link to="/profile" className='profile-btn font-16'><img src={logo} alt="College" /></Link>
            <button className='close_mobileMenu' onClick={(e) => handleClick(e)}><FaTimes /></button>
          </div>
          <ul>
            {
              menuItem && menuItem.map((menu) => {
                return (
                  <NavDropdown as='li' title={menu.title} key={menu.menu_id}>
                      {
                        menu.submenu.map((data) => {
                          return (
                            <NavDropdown.Item key={data.menu_id} to={menu.slug + "/" + data.slug} state={{ slug: menu.slug + "/" + data.slug }} >{data.title}</NavDropdown.Item>
                          )
                        })
                      }
                  </NavDropdown>
                )
              })
            }
            <NavDropdown as='li' title='Courses'>
            {
                  courses && courses.map((data) => {
                    return (
                      <NavDropdown.Item key={data.course_id} to={"course/" + data.course_slug} state={{ slug: data.course_slug }} onClick={(e) => handleNavLink(e)}>{data.course_name}</NavDropdown.Item>
                    )
                  })
                }
            </NavDropdown>
            {/* <li className='dropdown'>
              <Link to="" onClick={(e) => handleClickDrop(e)}>Courses</Link>
              <div className={submenu ? "submenu show" : "submenu"}>
                {
                  courses && courses.map((data) => {
                    return (
                      <div key={data.course_id}>
                        <Link to={"course/" + data.course_slug} state={{ slug: data.course_slug }} onClick={(e) => handleNavLink(e)}>{data.course_name}</Link>
                      </div>
                    )
                  })
                }
              </div>
            </li>
            {
              menuItem && menuItem.map((menu) => {
                return (
                  <li className='dropdown' key={menu.menu_id}>
                    <Link to="" onClick={(e) => handleClickDrop(e)}>{menu.title}</Link>
                    <div className={submenu ? "submenu show" : "submenu"}>
                      {
                        menu.submenu.map((data) => {
                          return (
                            <div key={data.menu_id}>
                              <Link to={menu.slug + "/" + data.slug} state={{ slug: menu.slug + "/" + data.slug }} >{data.title}</Link>
                            </div>
                          )
                        })
                      }
                    </div>
                  </li>
                )
              })
            } */}
            <li>
              <Link to="/college">Colleges</Link>
            </li>
            <li>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              </NavDropdown>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default MainNavbar
