import React, { useEffect } from 'react'
import BreadcrumbSection from '../components/BreadcrumbSection'
import { Row, Col, Container } from 'react-bootstrap'
import img from "../images/breadcrumb/notfound.png"
import { Link } from 'react-router-dom'

const NotFound = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "CollegeVidhyarthi | Error 404"
  }, [])

  return (
    <section className='notFound-section mb-60'>
      {/* <BreadcrumbSection img={breadcrumbImg} activePage="Not found" pageTitle="Not Found" /> */}
      <BreadcrumbSection img='' activePage="Not found" pageTitle="Not Found" />
      <Container fluid>
        <Row className='align-items-center'>
          <Col md={4}>
            <img src={img} alt="Not Found" className='img-fluid' />
          </Col>
          <Col md={8} className='mt-md-0 mt-5'>
            <h2>Oops! Can't find anything.</h2>
            <p>Unfortunately the page you were looking for could not be found. It may be temporarily unavailable, moved or no longer exists.</p>
            <div className="back-links">
              <Link to="/" className='secondary-btn me-4 mb-2'>Home</Link>
              <Link to="college" className='secondary-btn me-4 mb-2'>Colleges</Link>
              <Link to={"stream/medical"} state={{ slug: "medical" }} className='secondary-btn me-4 mb-2'>Top Medical Colleges</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default NotFound