import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import BreadcrumbSection from '../components/BreadcrumbSection';
import { Link } from 'react-router-dom';

const News = () => {
  const [news, setNews] = useState();

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/news`);
      setNews(res.data.news);
    }
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "News | CollegeVidhyarthi"
  }, []);

  return (
    <div>
      <Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#link">Link</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
    <Navbar.Collapse id="basic-navbar-navvvv">
      <Nav className="me-auto">
        <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#link">Link</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdownnn">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
      <BreadcrumbSection img='' activePage='News' pageTitle='News' />
      <section className='news-page-section pb-40'>
        <Container fluid>
          <Row>
            {news &&
              news.map((data) => {
                return (
                  <Col lg={3} md={4} sm={6} key={data.news_id}>
                    <Link to={data.slug} state={{ slug: data.slug }} className='news-block'>
                      <img src={window.imgURL + '/news/' + data.image} alt='' />
                      <div className='news-content'>
                        <h6> {data.title} </h6>
                        <div className='d-flex justify-content-between'>
                          <b> {data.author} </b>
                          <span>{data.date}</span>
                        </div>
                        <div className="newsShort">
                        <p
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
          </Row>
          {/* <Slider {...settings}>
          {
            news.map((item) => {
              return (
                <div key={item.id}>
                  <div className="news-block">
                    <img src={item.img} alt={item.alt} />
                    <div className="news-content">
                      <h6> {item.title} </h6>
                      <span>{item.publishDate}</span>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Slider> */}
        </Container>
      </section>
    </div>
  );
};

export default News;
