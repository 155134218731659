import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from "../images/whyus/one.png"
import img2 from "../images/whyus/two.png"
import img3 from "../images/whyus/three.png"

const Whyus = () => {
  return (
    <section className='whyus-section mb-60'>
      <Container fluid>
          <Row>
            <Col lg={2} md={3} sm={4} xs={6} className="mb-lg-0 mb-5">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img1} alt="Why us" />
</div>
              <h6>No hidden charges</h6>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6} className="mb-lg-0 mb-5">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img2} alt="Why us" />
</div>
              <h6>1 on 1 Counselling</h6>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6} className="mb-md-0 mb-5 mt-sm-0 mt-3">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img3} alt="Why us" />
</div>
              <h6>100% Online Process</h6>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6}  className="mt-md-0 mt-sm-4 mt-3">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img1} alt="Why us" />
</div>
              <h6>No hidden charges</h6>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6}  className="mt-lg-0 mt-md-4 mt-sm-4 mt-3">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img2} alt="Why us" />
</div>
              <h6>1 on 1 Counselling</h6>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6}  className="mt-lg-0 mt-md-4 mt-sm-4 mt-3">
              <div className="whyus-block">
                <div className="whyus-img">
              <img src={img3} alt="Why us" />
</div>
              <h6>100% Online Process</h6>
              </div>
            </Col>
          </Row>
        </Container> 
    </section>
  )
}

export default Whyus
