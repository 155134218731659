import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const SearchSection = () => {
  const [searchText, setSearchText] = useState('')

  const handleChange = (event) => {
    setSearchText(event.target.value)
  }

  return (
    <section className='search-section'>
      <Container fluid>
        <h2>A one-step solution for your education needs!</h2>
        <div className="search-block">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <FaSearch />
            </span>
            <input type="text"className='form-control' placeholder='What are you looking for?' onChange={handleChange} value={searchText} />
            {/* <input type="text" className='form-control' placeholder="What are you looking for ?" /> */}
            {/* <button className='primary-btn'>Search</button> */}
          {/* <Button onClick={() => { navigate('/search' , { state: { slug: searchText } })}} className='primary-btn'>Search</Button> */}
              <Link to={"search?search=" + searchText} state={{ slug: searchText }} className='primary-btn home-search'>Search</Link>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SearchSection
