import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Col, Row } from 'react-bootstrap'
import BreadcrumbSection from '../components/BreadcrumbSection';
import CommonSection from '../components/CommonSection'
import { useLocation, useNavigate } from 'react-router-dom';
import loadingImg from '../images/loading.png';

const SingleNews = () => {
  let navigate = useNavigate();
  let { pathname } = useLocation();
  const [newsDetails, setNewsDetails] = useState()

  useEffect(() => {
    axios.get(`${window.apiURL}${pathname}`)
    .then((res)=>{
      setNewsDetails(res.data.news)
    })
    .catch(err => {
      navigate('/')
    })
    // async function getData() {
    //   const res = await axios.get(`${window.apiURL}${pathname}`)
    //   setNewsDetails(res.data.news)
    // }
    // getData();
  }, [pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    image,
    title,
    author,
    date,
    description
  } = newsDetails || {};

  useEffect(() => {
    document.title = `CollegeVidhyarthi | ${title}`
   }, [title]);

  return ( 
    newsDetails ?
  <div>
      <BreadcrumbSection img='' activePage={title} pageTitle={title} extraUrlText={"News"} extraUrl={"News"} />
    <section className="singleNews-section pb-40">
      <Container fluid>
        <Row>
          <Col lg={9}>
            <div className="left-news-detail">
              <h5>{title}</h5>
              <div className="d-flex justify-content-between mb-3">
                <div className='name'>{author}</div>
                <div className='light-text'>Updated on: {date}</div>
              </div>
              {/* <div className="headline">The constant rise in the number of Omicron cases poses an important question in front of the board authorities: Would Board Exams 2022 be delayed or cancelled altogether? Read below to know.</div> */}
              {
                image && <img src={window.imgURL + "//news/" + image} alt={title} className='img-fluid' />
              }
              <div>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </Col>
          <Col lg={3} className='d-lg-block d-none'>
            <CommonSection />
          </Col>
        </Row>
      </Container>
    </section>
  </div>
   : <div className='loading'>
   <img src={loadingImg} alt='Loading...' />
 </div>
  )
};

export default SingleNews;
