import React, { useEffect } from 'react'
import BreadcrumbSection from '../components/BreadcrumbSection'
import { Container } from 'react-bootstrap'
import Heading from '../components/Heading'

const Terms = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = `CollegeVidhyarthi | Terms`
  }, [])

  return (
    <section className='terms-section mb-60'>
        <BreadcrumbSection img='' activePage="Terms &amp; Conditions" pageTitle="Terms &amp; Conditions" />
      <Container fluid>
      <Heading heading="Website Usage Policy" />
        <h4>Please read carefully before using the portal.</h4>
        <p>Welcome to the website "CollegeVidhyarthi.com” which is owned by "CollegeVidhyarthi” These terms and conditions cover every individual visiting this website and it is assumed that by accessing and using CollegeVidhyarthi.com, you accept this “Terms of Use” in full and have gone through and consent univocally and irrevocably to the same. If you disagree with these conditions you must not use this website.</p>
        <h4>Website Overview:</h4>
        <p>CollegeVidhyarthi is the manager, moderator and operator of the website CollegeVidhyarthi.com and all of its versions including mobile and applications. CollegeVidhyarthi.com is a search engine for the courses or colleges/institutions and assists students by providing information on the admission process details of the courses, colleges/institutions of their interest. CollegeVidhyarthi guides students through the application process and acquaints them with all the available study options. CollegeVidhyarthi does not take applications/registrations on behalf of any college or institution irrespective of whether they are partner college or other listed college. By applying to any course or college/institution on the website, the student is deemed to have applied for the assistance of CollegeVidhyarthi team.</p>
        <h4>Eligibility:</h4>
        <p>The website is not designed to attract the users below 13 years and thus the company does not intend to keep data of the users below the specified age. By assessing or using the portal, the user is deemed to have warranted and represented that he/she fulfils the aforesaid requirement of minimum. Upon receipt of any information contrary to the aforesaid, the user and its information shall be deleted.</p>
        <h4>Creating a user account on CollegeVidhyarthi.com:</h4>
        <p>The website intents to protect private information provided by the user while creating a user account as per the terms and conditions specified herein. By creating an account, the user agrees to the terms required to protect the confidentiality of username and password for the account and shall be solely liable for any activity conducted using the user account. The user also agrees to accept all risk and responsibilities for activity done under the username. User also agrees to receive SMS and Emails containing the information of the college and course he/she applies in and also of related colleges and courses.</p>
        <h4>Privacy Policy:</h4>
        <p>The privacy policy is applicable only for CollegeVidhyarthi and not for the websites linked through it, which shall be governed by its respective privacy policies and/or terms of use. The website utilises cookies and other tracking technologies. Some cookies and other technologies may serve to recall Personal Information previously indicated by a Web user. Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. By providing us your information in the ways stated hereinbelow, you agree with the privacy policy and give us consent for usage of information by us in the manner stated herein.</p>
        <h4>Information Collected by the Website:</h4>
        <p>Means of data collection on CollegeVidhyarthi are- Contact Forms (The contact forms on the website are made to simplify the process of data presentation by collecting the user interests, educational qualification and age, etc), and Login/Signup (By creating a user account visitor agrees the terms of conditions of the website. The data collected may consist of personal or non-personal information. We may store, collect, use and process your Information India subject to compliance under applicable laws. The website collects user information to provide suggestions tailored as per the students' profile and keep them informed about the ongoing application processes</p>
        <h4>Opt-in Mails and Other ways of data usage by the Company:</h4>
        <p>The company can use data collected through the website in various ways such as- (a) Opt-in Mails- Company sends customized mails on the users opting for newsletters or notification on admission process and entrance tests. Users can anytime unsubscribe from the service (b) Feedbacks: The Company may contact its visitors or users for feedback on any new feature or services. If not interested, users can drop a mail on privacy@CollegeVidhyarthi.com (c) Geographical Data: we use geographical or demographical data to provide location wise customized data. (D) Cookies and IP data are used to provide most relevant results through search. The website also stores data other than the content which provides information on personal identity in the form of IP and cookies. It is henceforth considered under the category of non personal-identifiable data which also includes search history, queries submitted, education interests, date and time, domain, advertisement response. The website can also use a conversion pixel (a 1px. X 1px. image) to track the user behavior on our leads collection form as it is an intact part of our Business. We can use the data collected through our channel in the analysis of trends and can also be forwarded to the educational service providers depending upon the usage history. The data so collected can be used in the following manner and ways:</p>
        <ul>
          <li>Email distribution/SMS/Telephone as described in the application form and for administrative purposes (such as to inform you and apprise of you of the information or any change herein for the college/course of your interested or related course/college). In accordance with instructions on the site, you may opt not to receive any such communication in the future.</li>
          <li>Display banner (and similar) advertising to you in connection with the site that is more targeted to you specifically. In doing this type of targeting, CollegeVidhyarthi or its ad server, connect you with the targeting criteria.</li>
        </ul>
        <p>By using the website and/or registering yourself with us you authorize us to contact you via email or phone call or SMS and offer you our services, imparting product knowledge, offer promotional offers running on website &amp; offers offered by third parties, for which reasons, personally identifiable information may be collected. And irrespective of the fact if also you have registered yourself under DND or DNC or NCPR service, you still authorize us to give you a call from CollegeVidhyarthi for the above mentioned purposes till 365 days of your registration with us.</p>
        <h4>Third-party content:</h4>
        <p>The users providing ‘testimonials’ are entitled to public display and can also be shared with our partner institutions. By using this website, the user agrees to not use any foul or offensive language in any forum or publicly available comment section. Because we do not control the third party content, user understand and agree that:</p>
        <ul>
          <li>
          We are not responsible for, and do not endorse, any such content, including information and reviews about any college/institution provided by other users
          </li>
          <li>We make no guarantees about the accuracy, currency, suitability, reliability or quality of the information in such content</li>
          <li>We assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by users, advertisers, and third parties.</li>
        </ul>
        <p>We do not claim ownership in any content that the user submits or authorizes for use to us, the user, however, agrees that such content shall be exclusive to us and no such or similar shall be published, copied, reproduced by the user or any person visiting the website on any of the other online portal, without obtaining a prior consent in writing by CollegeVidhyarthi. The user is free to remove or get removed his/her own content, if such content is posted wither accidentally or unintentionally on the website.</p>
        <h4>For any clarification, please contact</h4>
        <p>CollegeVidhyarthi<br />Head Office - Jaipur 
        Head Office - Jaipur<br />Branch office - Kota<br /><a href="mailto: infocollegevidhyarthi@gmail.com" className='text-primary'>infocollegevidhyarthi@gmail.com</a></p>
      </Container>
    </section>
  )
}

export default Terms