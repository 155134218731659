import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BreadcrumbSection from '../components/BreadcrumbSection';
import { Col, Container, Form, Row, Tab, Tabs, Modal, Button } from 'react-bootstrap';
import { FaBook, FaCalendarAlt, FaMapMarkerAlt, FaMoneyCheckAlt, FaUniversity, FaTimes } from 'react-icons/fa';
// import StarRatings from 'react-star-ratings';
import CommonSection from '../components/CommonSection';
import Slider from 'react-slick/lib/slider';
import loadingImg from '../images/loading.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CollegeDetails = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const [details, setDetails] = useState();
  const [facility, setFacility] = useState();
  const [courses, setCourses] = useState();
  const [images, setImages] = useState();
  const [placements, setPlacements] = useState();
  const [showApply, setShowApply] = useState(false);
  const [imageShow, setimageShow] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    course: '',
    college: '',
    enquiry_type: 'college'
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.get(`${window.apiURL}${pathname}`)
      .then((res) => {
        setDetails(res.data.collegedetails);
        setFacility(res.data.facility);
        setCourses(res.data.course);
        setPlacements(res.data.placement);
        setImages(res.data.gallery_image);
      })
      .catch(err => {
        navigate('/')
      })
    // async function getData() {
    // const res = await axios.get(
    //   `${window.apiURL}${pathname}`
    //   )
    // if(slug){
    //   res = await axios.get(
    //     `https://mycollege.proftcode.in/api/college/${slug}`
    //   )
    // }
    // else {
    //   res = await axios.get(
    //     `https://mycollege.proftcode.in/api${pathname}`
    //   )
    // }
    //   setDetails(res.data.collegedetails);
    //   setFacility(res.data.facility);
    //   setCourses(res.data.course);
    //   setPlacements(res.data.placement);
    //   setImages(res.data.gallery_image);
    //   setLoading(false);
    //   navigate('/')
    // }
    // getData();

  }, [pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if(formData.name === '' || formData.email === '' || formData.mobile === '' || formData.city === '' || formData.state === '' || formData.course === ''){
      const notify = () => toast.error("Please provide all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: 'colored'
      })
      notify()
    }
    else {
      try {
        await axios.post(`${window.apiURL}/enquiry`, {
          name: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          city: formData.city,
          state: formData.state,
          course: formData.course,
          college: college_name,
          enquiry_type: formData.enquiry_type
        })
        console.log(`college: ${formData.college}`)
        const notify = () => toast.success("Successfully Submitted", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: 'colored'
        })
        notify()
      }
      catch (error) {
        alert(error)
      }
    }
  }

  const showApplyModal = (e) => {
    e.preventDefault();
    setShowApply(true);
  };
  const hideApplyModal = (e) => {
    e.preventDefault();
    setShowApply(false);
  };

  const imageCloseModal = () => setimageShow(false);
  const imageShowModal = () => setimageShow(true);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    college_logo,
    college_name,
    college_type,
    all_course,
    address,
    approve_title,
    course_offer,
    fee_range,
    banner_image,
    overview,
    established_in,
    admission,
    placement,
    college_brochure,
    admission_url
  } = details || {};

  useEffect(() => {
    document.title = `CollegeVidhyarthi | ${college_name}`
  }, [college_name]);

  return (
    details ? <div>
      {
        banner_image && <BreadcrumbSection img={window.imgURL + "//bannerimage/" + banner_image} activePage={college_name} pageTitle={college_name} extraUrlText={"All Colleges"} extraUrl={"college"} />
      }
      <section className='college-details-section'>
        <Container fluid>
          <div className='top-apply-section'>
            <div className='d-flex align-items-center'>
              <div className="clg-logoo">
                {college_logo && (
                  <img
                    src={window.imgURL + '//collegelogo/' + college_logo}
                    alt={college_name}
                  />
                )}
              </div>
              {/* <h3 className='college-title d-md-none d-inline-block'>
                {college_name}
              </h3> */}
            </div>
            <div className='right-desc'>
              <h3 className='college-title'>
                {college_name}
              </h3>
              <div className='d-md-flex justify-content-between align-items-center'>
                <div>
                  <div className='d-sm-flex align-items-center location-details mt-md-0 mt-2'>
                    <div className='me-5'>
                      <FaMapMarkerAlt /> <span>{address}</span>
                    </div>
                    <div>
                      <span className='fw-bold'>Approved by:</span>{' '}
                      <span>{approve_title}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='d-flex align-items-center justify-content-md-end justify-content-start'>
                    {/* <div className='mt-md-0 mt-2'>
                      <a href={`https://mycollege.proftcode.in/public/upload//collegebrochure/${college_brochure}`} className='secondary-btn' download>
                        Download Brochure
                      </a>
                    </div> */}
                    <div className='mt-md-0 mt-2'>
                    {
                        admission_url === null ?
                      <Link to=""
                        className='primary-btn top-apply-btn'
                        onClick={showApplyModal}
                      >
                        <span>Apply Now</span>
                       <div className="apply-imgg"> <svg width="32" height="16" viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><path d="M31.426 15.948l.036-.008.03-.008.034-.012.03-.01.032-.015.03-.014.029-.017.03-.018c.01-.006.019-.014.029-.021l.025-.018a.684.684 0 00.043-.039l.008-.007.007-.008a.692.692 0 00.038-.041l.02-.027.02-.027c.008-.01.014-.02.02-.03l.016-.027.015-.032.014-.029.012-.031c.003-.01.008-.021.01-.032l.01-.032.007-.032.006-.039.004-.027a.668.668 0 00.004-.068V.705a.688.688 0 00-.694-.681H9.423a.713.713 0 00-.357.108.81.81 0 00-.104.082.732.732 0 00-.027.026l-.013.014-.007.008a.675.675 0 00-.025.03l-.017.024a.7.7 0 00-.02.031l-.017.026-.016.031-.014.029-.013.031-.011.031-.01.032-.008.033-.006.03a.783.783 0 00-.005.036l-.003.03a.659.659 0 00-.002.038V4.91c0 .377.31.682.693.682a.688.688 0 00.694-.682V2.22l6.167 5.367a.698.698 0 00-.092.075l-6.075 5.971v-4.48a.688.688 0 00-.694-.682.688.688 0 00-.693.682v.833H5.164a.688.688 0 00-.694.681c0 .377.31.682.694.682h3.584v3.93c0 .377.31.682.693.682h21.85a.717.717 0 00.068-.003l.032-.005.035-.005zm-.829-2.315l-6.075-5.971-.012-.01 4.567-3.81a.674.674 0 00.08-.96.702.702 0 00-.977-.08l-7.804 6.509-9.105-7.924h19.326v12.246zM17.191 8.626a.68.68 0 00.121-.16l2.594 2.257a.7.7 0 00.909.01l2.644-2.205a.688.688 0 00.082.098l6.075 5.972h-18.5l6.075-5.972z"></path><path d="M.694 7.789h11.715a.688.688 0 00.693-.682.688.688 0 00-.693-.682H.694A.688.688 0 000 7.107c0 .377.31.682.694.682zM1.908 4.031h5.26a.688.688 0 00.693-.681.688.688 0 00-.693-.682h-5.26a.688.688 0 00-.694.682c0 .376.31.681.694.681zM5.78 12.865H.694a.688.688 0 00-.694.681c0 .377.31.682.694.682H5.78a.688.688 0 00.694-.682.688.688 0 00-.694-.681z"></path></g></svg></div>
                      </Link>
                      :
                    <a href={admission_url} className="primary-btn top-apply-btn">
                        <span>Apply Now</span>
                       <div className="apply-imgg"> <svg width="32" height="16" viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><path d="M31.426 15.948l.036-.008.03-.008.034-.012.03-.01.032-.015.03-.014.029-.017.03-.018c.01-.006.019-.014.029-.021l.025-.018a.684.684 0 00.043-.039l.008-.007.007-.008a.692.692 0 00.038-.041l.02-.027.02-.027c.008-.01.014-.02.02-.03l.016-.027.015-.032.014-.029.012-.031c.003-.01.008-.021.01-.032l.01-.032.007-.032.006-.039.004-.027a.668.668 0 00.004-.068V.705a.688.688 0 00-.694-.681H9.423a.713.713 0 00-.357.108.81.81 0 00-.104.082.732.732 0 00-.027.026l-.013.014-.007.008a.675.675 0 00-.025.03l-.017.024a.7.7 0 00-.02.031l-.017.026-.016.031-.014.029-.013.031-.011.031-.01.032-.008.033-.006.03a.783.783 0 00-.005.036l-.003.03a.659.659 0 00-.002.038V4.91c0 .377.31.682.693.682a.688.688 0 00.694-.682V2.22l6.167 5.367a.698.698 0 00-.092.075l-6.075 5.971v-4.48a.688.688 0 00-.694-.682.688.688 0 00-.693.682v.833H5.164a.688.688 0 00-.694.681c0 .377.31.682.694.682h3.584v3.93c0 .377.31.682.693.682h21.85a.717.717 0 00.068-.003l.032-.005.035-.005zm-.829-2.315l-6.075-5.971-.012-.01 4.567-3.81a.674.674 0 00.08-.96.702.702 0 00-.977-.08l-7.804 6.509-9.105-7.924h19.326v12.246zM17.191 8.626a.68.68 0 00.121-.16l2.594 2.257a.7.7 0 00.909.01l2.644-2.205a.688.688 0 00.082.098l6.075 5.972h-18.5l6.075-5.972z"></path><path d="M.694 7.789h11.715a.688.688 0 00.693-.682.688.688 0 00-.693-.682H.694A.688.688 0 000 7.107c0 .377.31.682.694.682zM1.908 4.031h5.26a.688.688 0 00.693-.681.688.688 0 00-.693-.682h-5.26a.688.688 0 00-.694.682c0 .376.31.681.694.681zM5.78 12.865H.694a.688.688 0 00-.694.681c0 .377.31.682.694.682H5.78a.688.688 0 00.694-.682.688.688 0 00-.694-.681z"></path></g></svg></div>
                      </a>
}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='d-flex mt-2'>
                <StarRatings
                  rating={rating}
                  starRatedColor='#ffc400'
                  starEmptyColor='#d9d9d9'
                  numberOfStars={5}
                  starSpacing='2px'
                />
                <div className='ms-3 mt-1'>{review} Reviews</div>
              </div> */}
            </div>
          </div>
        </Container>
        <div className='college-details-tab'>
          <Tabs defaultActiveKey='overview'>
            <Tab eventKey='overview' title='Overview'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div className='left-college-detail'>
                      <div className='college-features'>
                        <Row>
                          <Col md={3} xl={3} lg={6} sm={6}>
                            <div className='college-feature-box'>
                              <Row className='align-items-center'>
                                <Col xs={3}>
                                  <FaUniversity />
                                </Col>
                                <Col xs={9}>
                                  <span className='light-text'>
                                    College Type
                                  </span>
                                  <span>{college_type}</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            md={3}
                            xl={3}
                            lg={6}
                            sm={6}
                            className='mt-sm-0 mt-4'
                          >
                            <div className='college-feature-box'>
                              <Row className='align-items-center'>
                                <Col xs={3}>
                                  <FaCalendarAlt />
                                </Col>
                                <Col xs={9}>
                                  <span className='light-text'>
                                    Established In
                                  </span>
                                  <span>{established_in}</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            xl={3}
                            lg={6}
                            md={3}
                            sm={6}
                            className='mt-xl-0 mt-xl-0 mt-lg-4 mt-md-0 mt-4'
                          >
                            <div className='college-feature-box'>
                              <Row className='align-items-center'>
                                <Col xs={3}>
                                  <FaMoneyCheckAlt />
                                </Col>
                                <Col xs={9}>
                                  <span className='light-text'>Fee Range</span>
                                  <span>{fee_range}</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            xl={3}
                            lg={6}
                            md={3}
                            sm={6}
                            className='mt-xl-0 mt-xl-0 mt-lg-4 mt-md-0 mt-4'
                          >
                            <div className='college-feature-box'>
                              <Row className='align-items-center'>
                                <Col xs={3}>
                                  <FaBook />
                                </Col>
                                <Col xs={9}>
                                  <span className='light-text'>
                                    Courses Offered
                                  </span>
                                  <span>{course_offer}</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className='description_college'
                        dangerouslySetInnerHTML={{ __html: overview }}
                      />
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='facilities' title='Facilities'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div className='left-college-detail'>
                      <div className='college-features'>
                        <Row className='g-4'>
                          {facility &&
                            facility.map((data) => {
                              return (
                                <Col
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  key={data.facility_id}
                                >
                                  <div className='college-feature-box'>
                                    <Row className='align-items-center'>
                                      <Col xs={4} className='pe-0'>
                                        <img
                                          src={
                                            window.imgURL +
                                            '//facility/' +
                                            data.facility_icon
                                          }
                                          alt=''
                                          className='img-fluid'
                                        />
                                      </Col>
                                      <Col xs={8}>
                                        <span className='light-text'>
                                          {data.facility_title}
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='courses' title='Courses'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                  <div
                        className='description_college'
                        dangerouslySetInnerHTML={{ __html: all_course }}
                      />
                    {/* <p>{all_course}</p> */}
                    {courses &&
                      courses.map((data) => {
                        return (
                          <div key={data.id}>
                            <Link to="" className='course__box'>
                              <div className='d-flex justify-content-between heading-block'>
                                <div>
                                  <div>
                                    <h5>{data.course_name}</h5>
                                  </div>
                                  <div>
                                    <div className='me-5'>
                                      <span className='light-text'>Eligibility: </span>
                                      <span>{data.eligibility}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-1 text-end d-sm-block d-none'>
                                    <div>
                                      {/* <span className='light-text'>Fee: </span> */}
                                      <span className='detailsName_fees'>₹{data.fees}</span>
                                    </div>
                                  <button className='primary-btn' onClick={showApplyModal}>
                                    Apply Now
                                  </button>
                                </div>
                              </div>
                              <div className='desc'>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                />
                                <div className='mt-1 text-end d-sm-none d-block'>
                                  <button className='primary-btn' onClick={showApplyModal}>
                                    Apply Now
                                  </button>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='admissions' title='Admissions'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div>
                      <div
                        className='description_college'
                        dangerouslySetInnerHTML={{ __html: admission }}
                      />
                      {
                        admission_url === null ?
                          <Form className='mt-4 admission_form' onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="form--group" controlId="validationCustom01">
                                  <Form.Label>Full name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    placeholder="Full Name"
                                    onChange={handleChange} value={formData.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your Full Name.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form--group" controlId="validationCustom01">
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    onChange={handleChange} value={formData.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide valid Email.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form--group" controlId="validationCustom01">
                                  <Form.Label>Mobile No.</Form.Label>
                                  <Form.Control
                                    required
                                    type="text" name="mobile" placeholder='Mobile No.' onChange={handleChange} value={formData.mobile}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your Contact.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form--group" controlId="validationCustom01">
                                  <Form.Label>City You live in</Form.Label>
                                  <Form.Control
                                    required
                                    type="text" name="city" placeholder='City' onChange={handleChange} value={formData.city}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide this field.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form--group" controlId="validationCustom01">
                                  <Form.Label>State</Form.Label>
                                  <Form.Control
                                    required
                                    type="text" name="state" placeholder='State' onChange={handleChange} value={formData.state}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  Please provide this field.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form--group">
                                  <Form.Label>Course interested in</Form.Label>
                                  <select name="course" onChange={handleChange} value={formData.course} required>
                                    <option value='Selected'>Select</option>
                                    {
                                      courses && courses.map((item) => {
                                        return (
                                          <option value={item.course_name} key={item.course_name}>{item.course_name}</option>
                                        )
                                      })
                                    }
                                  </select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <input type="hidden" name="enquiry_type" value={formData.enquiry_type} />
                            <input type="hidden" name="college" />
                            <div className='d-inline-block mt-lg-0 mt-3'>
                              <input type='submit' value='Submit' />
                            </div>
                          </Form>
                          : <div className="mt-3 text-center"><a href={admission_url} className="primary-btn top-apply-btn"><span>Apply for Admission</span> <div className="apply-imgg"> <svg width="32" height="16" viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><path d="M31.426 15.948l.036-.008.03-.008.034-.012.03-.01.032-.015.03-.014.029-.017.03-.018c.01-.006.019-.014.029-.021l.025-.018a.684.684 0 00.043-.039l.008-.007.007-.008a.692.692 0 00.038-.041l.02-.027.02-.027c.008-.01.014-.02.02-.03l.016-.027.015-.032.014-.029.012-.031c.003-.01.008-.021.01-.032l.01-.032.007-.032.006-.039.004-.027a.668.668 0 00.004-.068V.705a.688.688 0 00-.694-.681H9.423a.713.713 0 00-.357.108.81.81 0 00-.104.082.732.732 0 00-.027.026l-.013.014-.007.008a.675.675 0 00-.025.03l-.017.024a.7.7 0 00-.02.031l-.017.026-.016.031-.014.029-.013.031-.011.031-.01.032-.008.033-.006.03a.783.783 0 00-.005.036l-.003.03a.659.659 0 00-.002.038V4.91c0 .377.31.682.693.682a.688.688 0 00.694-.682V2.22l6.167 5.367a.698.698 0 00-.092.075l-6.075 5.971v-4.48a.688.688 0 00-.694-.682.688.688 0 00-.693.682v.833H5.164a.688.688 0 00-.694.681c0 .377.31.682.694.682h3.584v3.93c0 .377.31.682.693.682h21.85a.717.717 0 00.068-.003l.032-.005.035-.005zm-.829-2.315l-6.075-5.971-.012-.01 4.567-3.81a.674.674 0 00.08-.96.702.702 0 00-.977-.08l-7.804 6.509-9.105-7.924h19.326v12.246zM17.191 8.626a.68.68 0 00.121-.16l2.594 2.257a.7.7 0 00.909.01l2.644-2.205a.688.688 0 00.082.098l6.075 5.972h-18.5l6.075-5.972z"></path><path d="M.694 7.789h11.715a.688.688 0 00.693-.682.688.688 0 00-.693-.682H.694A.688.688 0 000 7.107c0 .377.31.682.694.682zM1.908 4.031h5.26a.688.688 0 00.693-.681.688.688 0 00-.693-.682h-5.26a.688.688 0 00-.694.682c0 .376.31.681.694.681zM5.78 12.865H.694a.688.688 0 00-.694.681c0 .377.31.682.694.682H5.78a.688.688 0 00.694-.682.688.688 0 00-.694-.681z"></path></g></svg></div></a></div>
                      }
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='placement' title='Placement'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div
                      className='description_college mb-4'
                      dangerouslySetInnerHTML={{ __html: placement }}
                    />
                    <div className='placementRow'>
                      {placements &&
                        placements.map((data) => {
                          return (
                              <div className='placement_box' key={data.placement_id}>
                                <img
                                  src={
                                    window.imgURL +
                                    '//placement/' +
                                    data.company_logo
                                  }
                                  alt=''
                                />
                                <h4>{data.company_name}</h4>
                              </div>
                          );
                        })}
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='pictures' title='Pictures'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <Row className='gallery-images'>
                      {images &&
                        images.map((data) => (
                          <Col md={4} sm={6} key={data}>
                            <Button variant='primary' onClick={imageShowModal}>
                              <img
                                src={window.imgURL + '//galleryimage/' + data}
                                alt=''
                              />
                            </Button>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab>
            {/* <Tab eventKey='reviews' title='Reviews'>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <div>
                      With over a kilometre long frontage on the Mumbai-Pune
                      Expressway, AUM provides a 360-degree transformational
                      set-up. It is a picturesque 30-acre state-of-the-art
                      campus including a 5-acre modern sports complex and other
                      facilities like food plaza, ATM, salon, medical centre and
                      laundry within the campus along with a 2000 seater
                      on-campus hostel facility for both girls and boys. As of
                      today, AUM has built strategic partnerships with Tata
                      Technologies, CII School of Logistics, RICS School of
                      Built Environment, University of London and many others.
                      In addition, it has also tied up with IBM, CA
                      Technologies, Oracle, Sun Microsystems &amp; others for
                      skill enhancement of the students. Multiple
                      Inter-disciplinary research centres at the university have
                      not only appealed its students but also outsiders. At AUM,
                      the Corporate Resource Center (CRC) assures 100%
                      placements by positioning AUM as a preferred university
                      partner for internships, placements and other such campus
                      connect activities with the corporate world.
                    </div>
                  </Col>
                  <Col lg={3} className='d-lg-block d-none common-right-advertisement'>
                    <CommonSection />
                  </Col>
                </Row>
              </Container>
            </Tab> */}
          </Tabs>
        </div>
      <ToastContainer />
      </section>
      <Modal
        show={showApply}
        onHide={hideApplyModal}
        size='lg'
        id='applyNow-modal'
      >
        <Modal.Body>
          <Button className='close-btn' onClick={hideApplyModal}>
            <FaTimes />
          </Button>
          <div className='college-heading-block'>
            {college_logo && (
              <img
                src={window.imgURL + '//collegelogo/' + college_logo}
                alt={college_name}
              />
            )}
            <div className='ms-4'>
              <h4>Register Now to Apply</h4>
              <h6>{college_name}</h6>
            </div>
          </div>
          <Form className='mt-4 admission_form' onSubmit={handleSubmit} noValidate validated={validated}>
            <Row>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Full Name"
                    onChange={handleChange} value={formData.name}
                  />
                  <Form.Control.Feedback type="invalid">
                  Please provide Full Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    onChange={handleChange} value={formData.email}
                  />
                  <Form.Control.Feedback type="invalid">
                  Please provide valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    required
                    type="text" name="mobile" placeholder='Mobile No.' onChange={handleChange} value={formData.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                  Please provide your Contact.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>City You live in</Form.Label>
                  <Form.Control
                    required
                    type="text" name="city" placeholder='City' onChange={handleChange} value={formData.city}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide this field.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group" controlId="validationCustom01">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    required
                    type="text" name="state" placeholder='State' onChange={handleChange} value={formData.state}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide this field.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form--group">
                  <Form.Label>Course interested in</Form.Label>
                  <select name="course" onChange={handleChange} value={formData.course} required>
                    <option value='Selected'>Select</option>
                    {
                      courses && courses.map((item) => {
                        return (
                          <option value={item.course_name} key={item.course_name}>{item.course_name}</option>
                        )
                      })
                    }
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <input type="hidden" name="enquiry_type" value={formData.enquiry_type} />
            <input type="hidden" name="college" />
            <div className='d-inline-block mt-lg-0 mt-3'>
              <input type='submit' value='Submit' />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={imageShow}
        onHide={imageCloseModal}
        className='gallery-modal'
        centered
      >
        <Modal.Body>
          <Button className='close-btn' onClick={imageCloseModal}>
            <FaTimes />
          </Button>
          <Slider {...settings}>
            {images &&
              images.map((data) => (
                <div key={data}>
                  <img src={window.imgURL + '//galleryimage/' + data} alt='' />
                </div>
              ))}
          </Slider>
        </Modal.Body>
      </Modal>
    </div> : <div className='loading'>
      <img src={loadingImg} alt='Loading...' />
    </div>
  );
};

export default CollegeDetails;
