import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import BreadcrumbSection from '../components/BreadcrumbSection';

const Blog = () => {
  const [blog, setBlog] = useState()

  useEffect(() => {
    async function getData() {
      const res = await axios.get(`${window.apiURL}/blog`)
      setBlog(res.data.blogs)
    }
    getData();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "CollegeVidhyarthi | Blog"
  }, [])

  return <div>
    <BreadcrumbSection img='' activePage="Blog" pageTitle="Blog" />
    <section className="blog-section pb-40">
      <Container fluid>
        <Row>
          {blog && blog.map((data) => {
            return (
              <Col lg={4} sm={6} key={data.blog_id}>
                <Link  to={data.slug} state={{ slug: data.slug }} className='blog-box'>
                  {data.image && <img src={window.imgURL + "//blog/" + data.image} alt={data.title} className='img-fluid' />}
                  <div className="blog-content">
                    <h5> {data.title} </h5>
                    <div className='d-flex justify-content-between'>
                      <div className="author">{data.author}</div>
                      <div className="date">{data.date}</div>
                    </div>
                    <div className="blog-description">
                    <p dangerouslySetInnerHTML={{ __html: data.description }} />
                    </div>
                  </div>
                </Link>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  </div>;
};

export default Blog;
